/*!
 * Mobirise v4 theme (https://mobirise.com/)
 * Copyright 2017 Mobirise
 */
section {
  background-color: #eeeeee;
}

section,
.container,
.container-fluid {
  position: relative;
  word-wrap: break-word;
}

.form-control:focus {
  box-shadow: none;
}

:focus {
  outline: none;
}

@media (min-width: 992px) {
  .container-fluid {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

@media (max-width: 992px) {
  .md-pb {
    padding-bottom: 2rem !important;
  }
}
a {
  transition: color 0.25s;
}

a.mbr-iconfont:hover {
  text-decoration: none;
}

.article .lead p,
.article .lead ul,
.article .lead ol,
.article .lead pre,
.article .lead blockquote {
  margin-bottom: 0;
}

a {
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
a,
a:hover {
  text-decoration: none;
}

figure {
  margin-bottom: 0;
}

body {
  color: #003d59;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4 {
  line-height: 1;
  word-break: break-word;
  word-wrap: break-word;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  padding: 10px 0 10px 20px;
  position: relative;
  border-left: 2px solid;
  border-color: #003d59;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-delay: 9999s;
  transition-property: background-color, color;
}

textarea[type="hidden"] {
  display: none;
}

body {
  position: relative;
}

section {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
section .mbr-background-video,
section .mbr-background-video-preview {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.hidden {
  visibility: hidden;
}

.mbr-z-index20 {
  z-index: 20;
}

/*! Base colors */
.mbr-white {
  color: #ffffff;
}

.mbr-black {
  color: #202020;
}

.mbr-bg-white {
  background-color: #ffffff;
}

.mbr-bg-black {
  background-color: #000000;
}

/*! Text-aligns */
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

@media (max-width: 767px) {
  .align-left,
  .align-center,
  .align-right {
    text-align: center;
  }
}
/*! Font-weight  */
.mbr-light {
  font-weight: 300 !important;
}

.mbr-regular {
  font-weight: 400 !important;
}

.mbr-medium {
  font-weight: 500 !important;
}

.mbr-semibold {
  font-weight: 600 !important;
}

.mbr-bold {
  font-weight: 700;
}

/*! Media  */
.media-size-item {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}

.media-content {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}

.media-container-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}
.media-container-row .media-size-item {
  width: 400px;
}

.media-container-column {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.media-container-column > * {
  width: 100%;
}

@media (min-width: 992px) {
  .media-container-row {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
figure {
  overflow: hidden;
}

figure[mbr-media-size] {
  transition: width 0.1s;
}

.mbr-figure img,
.mbr-figure iframe {
  display: block;
  width: 100%;
}

.card {
  background-color: transparent;
  border: none;
}

.card-box {
  width: 100%;
}

.card-img {
  text-align: center;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}

.media {
  max-width: 100%;
  margin: 0 auto;
}

.mbr-figure {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}

.media-container > div {
  max-width: 100%;
}

.mbr-figure img,
.card-img img {
  width: 100%;
}

@media (max-width: 991px) {
  .media-size-item {
    width: auto !important;
  }

  .media {
    width: auto;
  }

  .mbr-figure {
    width: 100% !important;
  }
}
/*! Buttons */
.mbr-section-btn {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  font-size: 0;
}

nav .mbr-section-btn {
  margin-left: 0rem;
  margin-right: 0rem;
}

/*! Btn icon margin */
.btn .mbr-iconfont,
.btn.btn-sm .mbr-iconfont {
  cursor: pointer;
  margin-right: -0.5rem;
  padding-left: 10px;
}

.btn.btn-md .mbr-iconfont,
.btn.btn-md .mbr-iconfont {
  margin-right: 0.8rem;
}

[type="submit"] {
  -webkit-appearance: none;
}

/*! Full-screen */
.mbr-fullscreen .mbr-overlay {
  min-height: 100vh;
}

.mbr-fullscreen {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  -webkit-align-items: center;
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/*! Map */
.map {
  height: 25rem;
  position: relative;
}
.map iframe {
  width: 100%;
  height: 100%;
}

/* Form */
.form-asterisk {
  font-family: initial;
  position: absolute;
  top: -2px;
  font-weight: normal;
}

/*! Scroll to top arrow */
.mbr-arrow-up {
  bottom: 25px;
  right: 90px;
  position: fixed;
  text-align: right;
  z-index: 5000;
  color: #ffffff;
  font-size: 32px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.mbr-arrow-up a {
  background: #ffd8cc;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  height: 50px;
  width: 50px;
  outline-style: none !important;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
}
.mbr-arrow-up a:hover {
  background-color: #ffc4ab;
}
.mbr-arrow-up a i {
  line-height: 50px;
}

.mbr-arrow-up-icon {
  display: block;
  color: #fff;
}

.mbr-arrow-up-icon::before {
  content: "\203a";
  display: inline-block;
  font-family: serif;
  font-size: 32px;
  line-height: 1;
  font-style: normal;
  position: relative;
  top: 6px;
  left: -4px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/*! Arrow Down */
.mbr-arrow {
  position: absolute;
  bottom: 45px;
  left: 140%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.mbr-arrow > a {
  display: inline-block;
  text-decoration: none;
  outline-style: none;
  -webkit-animation: arrowdown 1.7s ease-in-out infinite;
  animation: arrowdown 1.7s ease-in-out infinite;
}
.mbr-arrow > a > i {
  position: absolute;
  top: -2px;
  left: 15px;
  font-size: 2rem;
}

@keyframes arrowdown {
  0% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
}
@-webkit-keyframes arrowdown {
  0% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
}
@media (max-width: 500px) {
  .mbr-arrow-up {
    left: 50%;
    right: auto;
    transform: translateX(-50%) rotate(180deg);
    -webkit-transform: translateX(-50%) rotate(180deg);
  }
}
/*Gradients animation*/
@keyframes gradient-animation {
  from {
    background-position: 0% 100%;
    animation-timing-function: ease-in-out;
  }
  to {
    background-position: 100% 0%;
    animation-timing-function: ease-in-out;
  }
}
@-webkit-keyframes gradient-animation {
  from {
    background-position: 0% 100%;
    animation-timing-function: ease-in-out;
  }
  to {
    background-position: 100% 0%;
    animation-timing-function: ease-in-out;
  }
}
.bg-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 5s infinite alternate;
  -webkit-animation: gradient-animation 5s infinite alternate;
}

@keyframes slidein {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
    display: none;
  }
}
@keyframes slideout {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.menu .navbar-brand {
  display: -webkit-flex;
}
.menu .navbar-brand span {
  display: flex;
  display: -webkit-flex;
}
.menu .navbar-brand .navbar-caption-wrap {
  display: -webkit-flex;
}
.menu .navbar-brand .navbar-logo img {
  display: -webkit-flex;
}
@media (min-width: 768px) and (max-width: 1253px) {
  .menu .navbar-toggleable-sm .navbar-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  }
}
@media (max-width: 1253px) {
  .menu .navbar-collapse {
    max-height: 93.5vh;
  }
  .menu .navbar-collapse.show {
    overflow: auto;
  }
}
@media (min-width: 1254px) {
  .menu .navbar-nav.nav-dropdown {
    display: -webkit-flex;
  }
  .menu .navbar-toggleable-sm .navbar-collapse {
    display: -webkit-flex !important;
  }
  .menu .collapsed .navbar-collapse {
    max-height: 93.5vh;
  }
  .menu .collapsed .navbar-collapse.show {
    overflow: auto;
  }
}
@media (max-width: 767px) {
  .menu .navbar-collapse {
    max-height: 80vh;
  }
}

.navbar {
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.navbar-collapse {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-align-items: center;
  align-items: center;
}

.nav-dropdown .link {
  margin: 0 15px;
}

.nav {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.justify-content-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.form-inline {
  display: -webkit-flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  align-items: center;
}

.card-wrapper {
  -webkit-flex: 1;
  flex: 1;
}

.carousel-control {
  z-index: 10;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.carousel-controls {
  display: -webkit-flex;
}

.media {
  display: -webkit-flex;
}

.form-group:focus {
  outline: none;
}

.jq-selectbox__select {
  padding: 0em 0em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 15px;
}

.jq-selectbox__dropdown {
  position: absolute;
  top: 100%;
  left: 0 !important;
  width: 100% !important;
}

.jq-selectbox__trigger-arrow {
  transform: translateY(-50%);
}

.jq-selectbox li {
  padding: 1.07em 0.5em;
}

input[type="range"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-dialog,
.modal-content {
  height: 100%;
}

.modal-dialog .carousel-inner {
  height: calc(100vh - 1.75rem);
}
@media (max-width: 575px) {
  .modal-dialog .carousel-inner {
    height: calc(100vh - 1rem);
  }
}

.carousel-item {
  text-align: center;
}

.carousel-item img {
  margin: auto;
}

.navbar-toggler {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  -webkit-border-radius: 0.25rem;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}

.navbar-toggler-left {
  position: absolute;
  left: 1rem;
}

.navbar-toggler-right {
  position: absolute;
  right: 1rem;
}

@media (max-width: 575px) {
  .navbar-toggleable .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }

  .navbar-toggleable > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-toggleable {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-toggleable .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-toggleable > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }

  .navbar-toggleable .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar-toggleable-sm .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }

  .navbar-toggleable-sm > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-toggleable-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-toggleable-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-toggleable-sm > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable-sm .navbar-collapse {
    display: none;
    width: 100%;
  }

  .navbar-toggleable-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1253px) {
  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }

  .navbar-toggleable-md > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1254px) {
  .navbar-toggleable-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-toggleable-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-toggleable-md > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable-md .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }

  .navbar-toggleable-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199px) {
  .navbar-toggleable-lg .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }

  .navbar-toggleable-lg > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-toggleable-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-toggleable-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-toggleable-lg > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-toggleable-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }

  .navbar-toggleable-lg .navbar-toggler {
    display: none;
  }
}
.navbar-toggleable-xl {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggleable-xl .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-toggleable-xl > .container {
  padding-right: 0;
  padding-left: 0;
}

.navbar-toggleable-xl .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-toggleable-xl .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-toggleable-xl > .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggleable-xl .navbar-collapse {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
}

.navbar-toggleable-xl .navbar-toggler {
  display: none;
}

.card-img {
  width: auto;
}

.menu .navbar.collapsed:not(.beta-menu) {
  flex-direction: column;
  -webkit-flex-direction: column;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.note-air-layout .dropup .dropdown-menu,
.note-air-layout .navbar-fixed-bottom .dropdown .dropdown-menu {
  bottom: initial !important;
}

html,
body {
  height: auto;
  min-height: 100vh;
}

.dropup .dropdown-toggle::after {
  display: none;
}

body {
  font-style: normal;
  line-height: 1.5;
}

.mbr-section-title {
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}

.mbr-section-subtitle {
  font-weight: 300;
  margin-bottom: 0;
}

.mbr-text {
  font-style: normal;
  margin-bottom: 0;
}

.mbr-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-title {
  line-height: 1.4;
}

.btn {
  font-weight: 700;
  line-height: 1;
  border-width: 1px;
  font-style: normal;
  letter-spacing: 0;
  margin: 0.4rem 0.8rem;
  white-space: normal;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  display: -webkit-inline-flex;
}
.btn span {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a.btn-md {
  width: 200px;
  min-height: 60px;
}

a.btn-lg {
  width: 220px;
  min-height: 70px;
}

a.btn {
  text-align: center;
  transition: all 0.3s;
  min-width: 170px;
}

.btn-form {
  margin: 0;
  border-radius: 0;
}
.btn-form:hover {
  cursor: pointer;
}

#scrollToTop a i:before {
  content: "";
}
#scrollToTop a i:after {
  content: "";
  position: absolute;
  display: block;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-top-right-radius: 3px;
  width: 30%;
  height: 30%;
  left: 35%;
  bottom: 40%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.mbr-arrow a {
  color: #ffffff;
}

@media (max-width: 767px) {
  .mbr-arrow {
    display: none;
  }
}
.form-control-label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0.357em;
  padding: 0;
}

.alert {
  color: #ffffff;
  border-radius: 0;
  border: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  position: relative;
}
.alert.alert-form::after {
  background-color: inherit;
  bottom: -7px;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  transform: rotate(45deg);
  width: 14px;
  -webkit-transform: rotate(45deg);
}

.form-control {
  box-shadow: none;
  color: #ffffff;
  line-height: 26px !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  border-radius: 4px;
  height: 70px;
}
.form-active .form-control:invalid {
  border-color: red;
}

.mbr-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

blockquote {
  font-style: italic;
  padding: 10px 0 10px 20px;
  font-size: 1.09rem;
  position: relative;
  border-width: 3px;
}

ul,
ol,
pre,
blockquote {
  margin-bottom: 2.3125rem;
}

pre {
  background: #f4f4f4;
  padding: 10px 24px;
  white-space: pre-wrap;
}

.inactive {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
}

.mbr-section__comments .row {
  justify-content: center;
  -webkit-justify-content: center;
}

.container {
  width: 100%;
}
@media (min-width: 1025px) {
  .container {
    padding-left: 115px;
    padding-right: 115px;
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  a.btn-lg {
    height: 60px;
  }
}

/*# sourceMappingURL=style.css.map */
.engine {
  position: absolute;
  text-indent: -2400px;
  text-align: center;
  padding: 0;
  top: 0;
  left: -2400px;
}
