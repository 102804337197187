@import url(https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);

body {
  font-family: Roboto;
}
.display-1 {
  font-family: "Oswald", sans-serif;
  font-size: 6.875rem;
  line-height: 1.04545455;
}
.display-1 > .mbr-iconfont {
  font-size: 11rem;
}
@media (max-width: 1440px) {
  .display-1 {
    font-size: 4.52302632rem;
    line-height: 1.064;
  }
}
@media (max-width: 1280px) {
  .display-1 {
    font-size: 4.02046784rem;
    line-height: 1.08818182;
  }
}
@media (max-width: 1024px) {
  .display-1 {
    font-size: 2.75rem;
    line-height: 1.09090909;
  }
}
.display-2 {
  font-family: "Oswald", sans-serif;
  font-size: 4.25rem;
  line-height: 1.05882353;
}
.display-2 > .mbr-iconfont {
  font-size: 6.8rem;
}
@media (max-width: 1440px) {
  .display-2 {
    font-size: 3.125rem;
    line-height: 1.14;
  }
}
@media (max-width: 1280px) {
  .display-2 {
    font-size: 45px;
    line-height: 52px;
    font-size: 2.81456954rem;
    line-height: 1.15470588;
  }
}
@media (max-width: 1024px) {
  .display-2 {
    font-size: 2.0631068rem;
    line-height: 1.09058824;
  }
}
.display-4 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.44444444;
}
.display-4 > .mbr-iconfont {
  font-size: 1.125rem;
}
@media (max-width: 1024px) {
  .display-4 {
    font-size: 1.00446429rem;
    line-height: 1.36888889;
  }
}
.display-5 {
  font-family: "Oswald", sans-serif;
  font-size: 2.75rem;
  line-height: 1.13636364;
}
.display-5 > .mbr-iconfont {
  font-size: 4.4rem;
}
@media (max-width: 1440px) {
  .display-5 {
    font-size: 2.2rem;
    line-height: 1.19318182;
  }
}
@media (max-width: 1280px) {
  .display-5 {
    font-size: 1.88356164rem;
    line-height: 0.99545455;
  }
}
@media (max-width: 1024px) {
  .display-5 {
    font-size: 1.5625rem;
    line-height: 1.2;
  }
}
.display-7 {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  line-height: 1.33333333;
}
.display-7 > .mbr-iconfont {
  font-size: 2.4rem;
}
@media (max-width: 1440px) {
  .display-7 {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}
@media (max-width: 1280px) {
  .display-7 {
    font-size: 1.12781955rem;
    line-height: 1.33;
  }
}
@media (max-width: 1024px) {
  .display-7 {
    font-size: 1.12781955rem;
    line-height: 1.27458333;
  }
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
/* Buttons */
.btn {
  padding: 12px 15px;
  border-radius: 4px;
}
.btn-sm {
  padding: 12px 15px;
  border-radius: 4px;
}
.btn-md {
  padding: 12px 15px;
  border-radius: 4px;
}
.btn-lg {
  padding: 21px 10px;
  border-radius: 4px;
}
.bg-primary {
  background-color: #014188 !important;
}
.bg-success {
  background-color: #014188 !important;
}
.bg-info {
  background-color: #4c4c4c !important;
}
.bg-warning {
  background-color: #f7941e !important;
}
.bg-danger {
  background-color: #fe5925 !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #014188 !important;
  border-color: #014188 !important;
  color: #ffffff !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #ffffff !important;
  background-color: #014188;
  border-color: #014188;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #014188 !important;
  border-color: #014188 !important;
}
.btn-primary:hover {
  background-color: #46877e !important;
  border-color: #46877e !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  color: #5e5e5e !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #5e5e5e !important;
  background-color: #dddddd;
  border-color: #dddddd;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #5e5e5e !important;
  background-color: #dddddd !important;
  border-color: #dddddd !important;
}
.btn-secondary:hover {
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
}
.btn-info,
.btn-info:active {
  background-color: #4c4c4c !important;
  border-color: #4c4c4c !important;
  color: #ffffff !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #4c4c4c !important;
  border-color: #4c4c4c !important;
}
.btn-info:hover {
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-success,
.btn-success:active {
  background-color: #014188 !important;
  border-color: #014188 !important;
  color: #ffffff !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #ffffff !important;
  background-color: #014188;
  border-color: #014188;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #014188 !important;
  border-color: #014188 !important;
}
.btn-success:hover {
  background-color: #012955 !important;
  border-color: #012955 !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #f7941e !important;
  border-color: #f7941e !important;
  color: #ffffff !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #ffffff !important;
  background-color: #f7941e;
  border-color: #f7941e;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff !important;
  background-color: #f7941e !important;
  border-color: #f7941e !important;
}
.btn-warning:hover {
  background-color: #f9ab4f !important;
  border-color: #f9ab4f !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #fe5925 !important;
  border-color: #fe5925 !important;
  color: #ffffff !important;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #fe5925;
  border-color: #fe5925;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #fe5925 !important;
  border-color: #fe5925 !important;
}
.btn-danger:hover {
  background-color: #fe8058 !important;
  border-color: #fe8058 !important;
}
.btn-white,
.btn-white:active {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #808080 !important;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #808080 !important;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #808080 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.btn-white:hover {
  background-color: #f7f7f7 !important;
  border-color: #f7f7f7 !important;
}
.btn-black,
.btn-black:active {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #333333;
  border-color: #333333;
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-black:hover {
  background-color: #666666 !important;
  border-color: #666666 !important;
}
.btn-primary-outline {
  border: 1px solid;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background: none;
  border-color: #014188;
  color: #014188;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #ffffff !important;
  background: #014188 !important;
  border-color: #014188;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #014188 !important;
  border-color: #014188 !important;
}
.btn-secondary-outline {
  border: 1px solid;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background: none;
  border-color: #dddddd;
  color: #dddddd;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #5e5e5e !important;
  background: #dddddd !important;
  border-color: #dddddd;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #5e5e5e !important;
  background-color: #dddddd !important;
  border-color: #dddddd !important;
}
.btn-info-outline {
  border: 1px solid;
}
.btn-info-outline,
.btn-info-outline:active {
  background: none;
  border-color: #4c4c4c;
  color: #4c4c4c;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #ffffff !important;
  background: #4c4c4c !important;
  border-color: #4c4c4c;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #4c4c4c !important;
  border-color: #4c4c4c !important;
}
.btn-success-outline {
  border: 1px solid;
}
.btn-success-outline,
.btn-success-outline:active {
  background: none;
  border-color: #014188;
  color: #014188;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #ffffff !important;
  background: #014188 !important;
  border-color: #014188;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #ffffff !important;
  background-color: #014188 !important;
  border-color: #014188 !important;
}
.btn-warning-outline {
  border: 1px solid;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background: none;
  border-color: #f7941e;
  color: #f7941e;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #ffffff !important;
  background: #f7941e !important;
  border-color: #f7941e;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #ffffff !important;
  background-color: #f7941e !important;
  border-color: #f7941e !important;
}
.btn-danger-outline {
  border: 1px solid;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background: none;
  border-color: #fe5925;
  color: #fe5925;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #ffffff !important;
  background: #fe5925 !important;
  border-color: #fe5925;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #fe5925 !important;
  border-color: #fe5925 !important;
}
.btn-black-outline {
  border: 1px solid;
}
.btn-black-outline,
.btn-black-outline:active {
  background: none;
  border-color: #333333;
  color: #333333;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #ffffff !important;
  background: #333333 !important;
  border-color: #333333;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-white-outline {
  border: 1px solid;
}
.btn-white-outline,
.btn-white-outline:active {
  background: none;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus,
.btn-white-outline.active {
  color: #808080 !important;
  background: #ffffff !important;
  border-color: #ffffff;
}
.btn-white-outline.disabled,
.btn-white-outline:disabled {
  color: #808080 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.btn-white-outline.btn {
  color: #333333 !important;
}
.text-primary {
  color: #014188 !important;
}
.text-secondary {
  color: #dddddd !important;
}
.text-success {
  color: #014188 !important;
}
.text-info {
  color: #4c4c4c !important;
}
.text-warning {
  color: #f7941e !important;
}
.text-danger {
  color: #fe5925 !important;
}
.text-white {
  color: #ffffff !important;
}
.text-black {
  color: #000000 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0265d4 !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #aaaaaa !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #001123 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #191919 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #f7941e !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #bc2e01 !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #666666 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #014188 !important;
}
.alert-success {
  background-color: #014188;
}
.alert-info {
  background-color: #4c4c4c;
}
.alert-warning {
  background-color: #f7941e;
}
.alert-danger {
  background-color: #fe5925;
}
.mbr-section-btn a.btn:not(.btn-form):hover,
.mbr-section-btn a.btn:not(.btn-form):focus {
  box-shadow: none;
}
.mbr-gallery-filter li.active .btn {
  background-color: #014188;
  border-color: #014188;
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
a,
a:hover {
  color: #014188;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #0b7efd;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #0b7efd;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #bfbfbf;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #fef2e3;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #fff4f0;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
.form-control {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  line-height: 1.33333333;
}
.form-control > .mbr-iconfont {
  font-size: 2.4rem;
}
@media (max-width: 1440px) {
  .form-control {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}
@media (max-width: 1280px) {
  .form-control {
    font-size: 1.12781955rem;
    line-height: 1.33;
  }
}
@media (max-width: 1024px) {
  .form-control {
    font-size: 1.12781955rem;
    line-height: 1.27458333;
  }
}
blockquote {
  border-color: #014188;
}
/* Forms */
.mbr-form .btn {
  margin: 0.4rem 0;
}
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #014188;
  color: #ffffff;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #014188;
  border-bottom-color: #014188;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker
  .xdsoft_timepicker
  .xdsoft_time_box
  > div
  > div.xdsoft_current {
  color: #ffffff !important;
  background-color: #014188 !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000000 !important;
  background: #dddddd !important;
  box-shadow: none !important;
}
.sidebar-block .mbr-gallery-item .galleryItem .onsale {
  top: 0px;
}
.sidebar-block .mbr-gallery-item .galleryItem .sidebar_wraper {
  bottom: -35px;
  margin-left: 1rem;
}
.link.display-4 a {
  font-size: 1.0125rem;
  line-height: 1;
}
.cid-s1pgD8z0jj {
  min-height: 114px !important;
  position: relative;
  overflow: visible;
  background: #ffffff;
}
.cid-s1pgD8z0jj .nav-link,
.cid-s1pgD8z0jj .navbar-caption {
  font-weight: 700;
  transition: all 0.3s linear;
}
.cid-s1pgD8z0jj .navbar-dropdown .navbar-brand span {
  vertical-align: 0;
}
.cid-s1pgD8z0jj .container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.cid-s1pgD8z0jj .nav-link.text-primary:hover,
.cid-s1pgD8z0jj .nav-link.text-primary:focus {
  color: #014188 !important;
}
.cid-s1pgD8z0jj .nav-link {
  position: relative;
  line-height: 1;
  margin: 0 !important;
  padding: 10px 20px !important;
}
.cid-s1pgD8z0jj .nav-link:hover:before {
  opacity: 1;
}
.cid-s1pgD8z0jj .nav-link:before {
  content: "";
  bottom: 0;
  width: calc(100% - 36px);
  left: 18px;
  height: 3px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #dddddd;
  transition: opacity 0.3s linear;
}
@media (max-width: 991px) {
  .cid-s1pgD8z0jj .nav-link {
    margin: 0 !important;
  }
}
.cid-s1pgD8z0jj .icons-menu {
  line-height: 0;
}
.cid-s1pgD8z0jj .icons__wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  font-size: 29px;
  margin-left: 15px;
}
.cid-s1pgD8z0jj
  .navbar-nav
  .nav-item:nth-child(2)
  .dropdown-menu
  .dropdown-item {
  width: 247px;
}
@media (min-width: 992px) {
  .cid-s1pgD8z0jj .navbar-nav .nav-item:nth-child(2) .dropdown-menu .dropdown {
    display: inline-block;
  }
}
.cid-s1pgD8z0jj .dropdown-menu .menu__images {
  display: flex;
  transform: translateY(-11px);
}
.cid-s1pgD8z0jj .dropdown-menu a {
  overflow: hidden;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image1 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours6-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image1:hover {
  transform: scale(1.3);
}
.cid-s1pgD8z0jj .dropdown-menu a > .image2 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours2-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image2:hover {
  transform: scale(1.3);
}
.cid-s1pgD8z0jj .dropdown-menu a > .image3 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours1-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image3:hover {
  transform: scale(1.3);
}
.cid-s1pgD8z0jj .dropdown-menu .dropdown-item {
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 10px 40px 10px 20px;
  line-height: 1;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}
@media (max-width: 1300px) {
  .cid-s1pgD8z0jj
    .navbar-nav
    .nav-item:nth-child(2)
    .dropdown-menu
    .dropdown-item {
    width: 167px;
    padding: 10px;
    white-space: normal;
  }
  .cid-s1pgD8z0jj .dropdown-menu a > .image1 {
    width: 170px;
    height: 140px;
  }
  .cid-s1pgD8z0jj .dropdown-menu a > .image2 {
    width: 170px;
    height: 140px;
  }
  .cid-s1pgD8z0jj .dropdown-menu a > .image3 {
    width: 170px;
    height: 140px;
  }
}
@media (min-width: 992px) {
  .cid-s1pgD8z0jj.dropdown.open
    > .dropdown-menu:not(.dropdown-submenu)
    > .dropdown
    > .dropdown-submenu {
    visibility: visible;
    opacity: 1;
  }
  .cid-s1pgD8z0jj .mbr-section-btn {
    margin-left: 31px;
  }
  .cid-s1pgD8z0jj .btn {
    white-space: nowrap;
  }
  .cid-s1pgD8z0jj .dropdown-menu {
    min-width: 210px;
    display: block;
    z-index: 5;
    background-color: #ffffff !important;
    border: 1px solid #ddebe9;
    border-radius: 4px;
    left: 17px;
    right: auto;
    top: 100%;
    opacity: 0;
    padding: 11px 0 !important;
    margin: 0 !important;
    visibility: hidden;
    transition: all 0.3s linear;
    transform: translateY(0);
  }
  .cid-s1pgD8z0jj .nav-item:hover .nav-link + .dropdown-menu {
    transform: translateY(20px);
    opacity: 1;
    visibility: visible;
  }
  .cid-s1pgD8z0jj .nav-item.dropdown:hover::before,
  .cid-s1pgD8z0jj .nav-item.dropdown.open:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
  }
  .is-builder .cid-s1pgD8z0jj .nav-item:hover .nav-link + .dropdown-menu {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1200px) {
  .cid-s1pgD8z0jj .navbar .navbar-collapse {
    align-items: center !important;
  }
}
.cid-s1pgD8z0jj .navbar > .container,
.cid-s1pgD8z0jj .navbar > .container-fluid {
  align-items: center;
}
.cid-s1pgD8z0jj .dropdown-toggle::after,
.cid-s1pgD8z0jj .link.dropdown-toggle:after {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 6px;
  content: "";
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  transition: all 0.25s ease 0s;
}
.cid-s1pgD8z0jj .dropdown-menu .dropdown-toggle:after {
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}
.cid-s1pgD8z0jj .open > .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-bottom: -8px;
  transform: rotate(225deg);
}
.cid-s1pgD8z0jj
  .dropdown.open
  .dropdown-toggle[aria-expanded="true"]
  + .dropdown-submenu {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.cid-s1pgD8z0jj .nav-dropdown .dropdown-submenu {
  display: none;
  margin: 0 !important;
  font-weight: 400;
  top: 0 !important;
}
.cid-s1pgD8z0jj .nav-item:focus,
.cid-s1pgD8z0jj .nav-link:focus {
  outline: none;
}
.cid-s1pgD8z0jj .dropdown .dropdown-menu .dropdown-item.dropdown-toggle:before {
  display: none;
}
.cid-s1pgD8z0jj .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-s1pgD8z0jj .collapsed .dropdown .dropdown-menu .dropdown-item {
  transition: none;
  margin: 0 !important;
}
.cid-s1pgD8z0jj a.nav-link {
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
}
.cid-s1pgD8z0jj ul.navbar-nav {
  flex-wrap: wrap;
  flex: 1;
}
.cid-s1pgD8z0jj .navbar {
  padding: 12px 52px 11px 43px;
  min-height: 114px;
  transition: all 0.3s;
  background: #ffffff;
}
.cid-s1pgD8z0jj .navbar.opened {
  transition: all 0.3s;
  background: #ffffff !important;
}
.cid-s1pgD8z0jj .navbar .navbar-collapse {
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.cid-s1pgD8z0jj .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-s1pgD8z0jj .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-s1pgD8z0jj .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(100vh - 100px - 1rem);
  }
}
.cid-s1pgD8z0jj .navbar.collapsed .dropdown-menu {
  background: transparent !important;
}
.cid-s1pgD8z0jj .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-s1pgD8z0jj .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-s1pgD8z0jj .dropdown-menu .menu__images {
    display: none;
  }
  .cid-s1pgD8z0jj .nav-item:hover .nav-link + .dropdown-menu {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .cid-s1pgD8z0jj .nav-item .nav-link::before {
    display: none;
  }
  .cid-s1pgD8z0jj.opened .dropdown-menu {
    top: 0;
  }
  .cid-s1pgD8z0jj .mbr-section-btn .btn {
    min-width: auto;
    padding: 5px;
    font-size: 14px;
  }
  .cid-s1pgD8z0jj .dropdown-menu {
    margin: 0;
    padding: 0;
    line-height: 45px;
    border: 0;
    left: 8px;
    width: 100%;
    max-width: 100% !important;
    box-shadow: none;
  }
  .cid-s1pgD8z0jj .dropdown-menu .dropdown-submenu {
    left: 0 !important;
    position: relative !important;
  }
  .cid-s1pgD8z0jj .navbar-logo {
    margin: 0;
  }
  .cid-s1pgD8z0jj .navbar-logo img {
    height: 60px !important;
  }
  .cid-s1pgD8z0jj .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    margin: 0 !important;
    padding: 0;
  }
  .cid-s1pgD8z0jj .navbar-toggler {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .cid-s1pgD8z0jj .icons-menu {
    margin: 30px 0;
    text-align: center;
  }
  .cid-s1pgD8z0jj .navbar {
    padding: 13px 90px;
  }
  .cid-s1pgD8z0jj .navbar .navbar-collapse {
    position: absolute;
    top: 100%;
    background-color: #ffffff !important;
    padding-left: 20px;
    padding-right: 20px;
    left: 0px;
    right: 0px;
  }
  .cid-s1pgD8z0jj .navbar .dropdown-menu .dropdown-item {
    justify-content: flex-start;
    padding: 15.5px 23px;
    line-height: 1;
  }
  .cid-s1pgD8z0jj .dropdown-submenu .dropdown-item {
    padding-left: 45px !important;
  }
  .cid-s1pgD8z0jj .dropdown-toggle::after,
  .cid-s1pgD8z0jj .link.dropdown-toggle:after {
    position: absolute;
    right: 12px;
  }
  .cid-s1pgD8z0jj .nav-item {
    border-bottom: 1px solid #ddebe9;
  }
  .cid-s1pgD8z0jj a.nav-link {
    justify-content: flex-start;
    line-height: 21px;
    padding: 35px 43px 35px 19px !important;
  }
  .cid-s1pgD8z0jj ul.navbar-nav {
    padding: 0 100px;
    margin: 0;
  }
  .cid-s1pgD8z0jj .navbar .dropdown.open > .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}
.cid-s1pgD8z0jj.navbar-short {
  background: #ffffff !important;
  min-height: 70px;
  height: 70px;
}
.cid-s1pgD8z0jj.navbar-short .nav-dropdown {
  height: 70px;
}
.cid-s1pgD8z0jj.navbar-short .navbar-brand {
  padding: 0;
}
.cid-s1pgD8z0jj.navbar-short .navbar-logo img {
  height: 70px !important;
}
@media (max-width: 767px) {
  .cid-s1pgD8z0jj .navbar {
    padding: 0 !important;
  }
  .cid-s1pgD8z0jj ul.navbar-nav {
    padding: 0 !important;
  }
  .cid-s1pgD8z0jj a.nav-link {
    padding: 27px 26px 27px 19px !important;
  }
}
.cid-s1pgD8z0jj .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 12px;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
}
.cid-s1pgD8z0jj .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-s1pgD8z0jj .dropdown-item.active,
.cid-s1pgD8z0jj .dropdown-item:active {
  background-color: transparent;
}
.cid-s1pgD8z0jj .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.cid-s1pgD8z0jj
  .navbar.navbar-expand-lg
  .dropdown
  .dropdown-menu
  .dropdown-submenu {
  left: 100%;
  position: absolute;
}
.cid-s1pgD8z0jj button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #f7941e;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.is-builder .cid-s1pgD8z0jj .dropdown.open > .dropdown-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(20px);
}
.cid-s1pgD8z0jj .navbar-dropdown {
  position: absolute;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-s1pgD8z0jj .navbar {
    height: 97px;
  }
  .cid-s1pgD8z0jj .navbar.opened {
    height: auto;
  }
  .cid-s1pgD8z0jj .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-s1pj7EandR {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-image: url("tours4-1-1200x800.jpg");
}
.cid-s1pj7EandR .mbr-section-title {
  margin-bottom: 35px;
}
.cid-s1pj7EandR .mbr-section-title b,
.cid-s1pj7EandR .mbr-section-title strong {
  font-weight: 500;
}
@media (max-width: 1024px) {
  .cid-s1pj7EandR .figure:before {
    width: 89px;
    height: 89px;
    top: -55px;
    left: -18px;
  }
}
.cid-s1pj7EandR .mbr-section-title,
.cid-s1pj7EandR .mbr-section-btn {
  color: #ffffff;
}
.cid-s1pjb34m3J {
  padding-top: 5rem;
  padding-bottom: 5rem;
  overflow: hidden;
  background-color: #ffffff;
}
.cid-s1pjb34m3J .two__background:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  max-width: 49%;
  width: 100%;
  height: 100%;
  background-color: #ececec;
  z-index: 0;
}
.cid-s1pjb34m3J .carousel-inner {
  margin-bottom: 70px;
}
.cid-s1pjb34m3J .slider__content {
  padding-top: 52px;
  text-align: left;
}
.cid-s1pjb34m3J .mbr-section-subtitle {
  color: #d3045f;
  margin-bottom: 13px;
  position: relative;
  z-index: 1;
}
.cid-s1pjb34m3J .mbr-section-subtitle:after {
  content: "";
  position: absolute;
  left: -92px;
  top: 147px;
  width: 85px;
  height: 85px;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("compass.svg");
}
.cid-s1pjb34m3J .mbr-section-title {
  margin-bottom: 33px;
  color: #014188;
}
.cid-s1pjb34m3J .slider__image {
  margin-left: 11.8%;
}
.cid-s1pjb34m3J .img-wrap {
  max-width: 100%;
}
.cid-s1pjb34m3J .img-wrap img {
  width: 100%;
  object-fit: cover;
}
.cid-s1pjb34m3J .carousel-indicators {
  display: flex;
  flex-direction: column;
  margin: 0;
  transition: all 0.25s;
  height: 100%;
  width: 50px;
  left: 44.5%;
  right: auto;
}
.cid-s1pjb34m3J .carousel-indicators .active {
  background: #014188;
  opacity: 1;
  border: none;
}
.cid-s1pjb34m3J .carousel-indicators .active:before {
  content: "";
  display: block;
  position: absolute;
  top: -14px;
  left: -14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: inherit;
  opacity: 0.2;
}
.cid-s1pjb34m3J .carousel-indicators li {
  width: 12px;
  height: 12px;
  border: none;
  margin-bottom: 32px;
  box-sizing: border-box;
  background-color: #014188;
  border-radius: 50%;
  position: relative;
  opacity: 0.2;
}
.cid-s1pjb34m3J .carousel-controls a {
  border-radius: 50%;
  border: 2px solid #014188;
  background-color: transparent;
}
.cid-s1pjb34m3J .carousel-controls a span {
  transition: all 0.3s;
  color: #014188;
  font-size: 18px;
}
.cid-s1pjb34m3J .carousel-controls a:hover .mobi-mbri-left {
  transform: translateX(-5px);
}
.cid-s1pjb34m3J .carousel-controls a:hover .mobi-mbri-right {
  transform: translateX(5px);
}
.cid-s1pjb34m3J .carousel-controls .carousel-control-next,
.cid-s1pjb34m3J .carousel-controls .carousel-control-prev {
  opacity: 1;
  width: 44px;
  height: 44px;
}
.cid-s1pjb34m3J .carousel-controls .carousel-control-next {
  right: 40%;
  bottom: 5px;
  top: auto;
  left: auto;
}
.cid-s1pjb34m3J .carousel-controls .carousel-control-prev {
  right: 45%;
  bottom: 5px;
  top: auto;
  left: auto;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-s1pjb34m3J .image-element {
    min-width: 50%;
  }
  .cid-s1pjb34m3J .media-container-row {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .cid-s1pjb34m3J .two__background:before {
    max-width: 90%;
  }
  .cid-s1pjb34m3J .carousel-indicators {
    flex-direction: row;
    height: 50px;
    width: 100%;
    left: auto;
    bottom: 0;
    right: auto;
  }
  .cid-s1pjb34m3J .carousel-indicators li {
    margin: 0 16px;
  }
  .cid-s1pjb34m3J .slider__content {
    padding-top: 0;
    margin-bottom: 72px;
  }
  .cid-s1pjb34m3J .mbr-section-subtitle {
    margin-bottom: 6px;
  }
  .cid-s1pjb34m3J .mbr-section-subtitle:after {
    content: none;
  }
  .cid-s1pjb34m3J .mbr-section-title {
    margin-bottom: 18px;
  }
  .cid-s1pjb34m3J .slider__image {
    margin-left: 0;
    margin-bottom: 100px;
  }
  .cid-s1pjb34m3J .img-wrap {
    width: 100%;
  }
  .cid-s1pjb34m3J .carousel-controls {
    display: none;
  }
  .cid-s1pjb34m3J .carousel-inner {
    margin-bottom: 0;
  }
}
.cid-s1pjb34m3J .mbr-text {
  color: #4c4c4c;
}
.cid-s1pjg3MPZQ {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.cid-s1pjg3MPZQ .mbr-section-title {
  font-weight: 500;
  color: #014188;
}
.cid-s1pjg3MPZQ .mbr-section-title b,
.cid-s1pjg3MPZQ .mbr-section-title strong {
  font-weight: 500;
}
.cid-s1pjg3MPZQ .link {
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
}
.cid-s1pjg3MPZQ .link a {
  font-weight: 700;
}
.cid-s1pjg3MPZQ .link span {
  display: inline-block !important;
  transition: all 0.3s ease;
  margin-left: 13px;
}
.cid-s1pjg3MPZQ .link:hover span {
  transform: translateX(9px);
}
.cid-s1pjg3MPZQ .card__block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.cid-s1pjg3MPZQ .card__block:nth-child(2) .card__content::before {
  background-color: #fe5925;
}
.cid-s1pjg3MPZQ .card__block:nth-child(3) .card__content::before {
  background-color: #014188;
}
.cid-s1pjg3MPZQ .card__block:nth-child(4) .card__content::before {
  background-color: #f7941e;
}
.cid-s1pjg3MPZQ .card__block:nth-child(6) .card__content::before {
  background-color: #014188;
}
.cid-s1pjg3MPZQ .card__block:nth-child(7) .card__content::before {
  background-color: #4c4c4c;
}
.cid-s1pjg3MPZQ .card__block:nth-child(8) .card__content::before {
  background-color: #dddddd;
}
.cid-s1pjg3MPZQ .card__content {
  position: relative;
  width: 100%;
  padding: 20px 44px 25px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}
.cid-s1pjg3MPZQ .card__content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  z-index: 1;
}
.cid-s1pjg3MPZQ .card__content:hover {
  transform: scale(0.96, 0.96);
}
.cid-s1pjg3MPZQ .card__content .card__image {
  display: flex;
  height: 190px;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin-bottom: 10px;
}
.cid-s1pjg3MPZQ .card__content .card__image img {
  max-width: 145px;
}
.cid-s1pjg3MPZQ .card__content .card__title {
  margin-bottom: 7px;
  font-weight: 600;
  position: relative;
  z-index: 5;
}
.cid-s1pjg3MPZQ .card__content .card__text {
  margin-bottom: 0;
  position: relative;
  z-index: 5;
}
@media (max-width: 1024px) {
  .cid-s1pjg3MPZQ .title__block {
    padding: 24px 17px;
  }
  .cid-s1pjg3MPZQ .card__content {
    padding: 15px 13px 20px;
  }
  .cid-s1pjg3MPZQ .card__content .card__image {
    height: 73px;
  }
  .cid-s1pjg3MPZQ .card__content .card__image img {
    max-width: 75px;
  }
}
@media (max-width: 767px) {
  .cid-s1pjg3MPZQ .row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .cid-s1pjg3MPZQ .card__block {
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .cid-s1pjg3MPZQ .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cid-s1pjg3MPZQ .card__content .card__title {
    font-size: 17px;
    line-height: 21px;
  }
  .cid-s1pjg3MPZQ .card__content .card__text {
    font-size: 14px;
    line-height: 18px;
  }
}
.cid-s1pjg3MPZQ .card__title {
  color: #f7941e;
}
.cid-s1pjdxRmJd {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.cid-s1pjdxRmJd .title__block {
  margin-bottom: 65px;
}
.cid-s1pjdxRmJd .nav-tabs {
  margin-bottom: 105px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #dddddd;
}
.cid-s1pjdxRmJd .nav-item {
  min-width: auto;
  margin: 0;
}
.cid-s1pjdxRmJd .nav-tabs .nav-link {
  box-sizing: border-box;
  color: #014188;
  font-weight: 700;
  padding: 0 36px 41px;
  border: none;
  border-radius: 0 !important;
  margin: 0;
  border-bottom: 11px solid transparent;
  background-color: transparent;
  transition: all 0.1s;
}
.cid-s1pjdxRmJd .nav-tabs .nav-link.active {
  font-weight: 700;
  border: none;
  background-color: transparent;
  border-bottom: 11px solid #f7941e;
}
.cid-s1pjdxRmJd .card__wrap {
  margin-bottom: 60px;
  display: flex;
}
.cid-s1pjdxRmJd .card__image {
  display: block;
  max-width: 150px;
  width: 100%;
  text-align: center;
}
.cid-s1pjdxRmJd .card__image img {
  width: 45%;
  object-fit: cover;
}
.cid-s1pjdxRmJd .card__content {
  display: block;
  width: 100%;
}
.cid-s1pjdxRmJd .card__title {
  margin-bottom: 15px;
  font-weight: 600;
  color: #014188;
}
.cid-s1pjdxRmJd .card__text {
  margin-bottom: 0;
  color: #4c4c4c;
}
@media (max-width: 991px) {
  .cid-s1pjdxRmJd .title__block {
    margin-bottom: 40px;
  }
  .cid-s1pjdxRmJd .nav-tabs {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 47px;
  }
  .cid-s1pjdxRmJd .nav-item {
    margin-bottom: 9px;
  }
  .cid-s1pjdxRmJd .nav-tabs .nav-link {
    padding-bottom: 25px;
  }
  .cid-s1pjdxRmJd .card__wrap {
    margin-bottom: 40px;
  }
  .cid-s1pjdxRmJd .card__title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .cid-s1pjdxRmJd .card__image {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .cid-s1pjdxRmJd .card__image {
    max-width: 90px;
  }
  .cid-s1pjdxRmJd .card__image img {
    width: 70%;
  }
}
.cid-s1pjdxRmJd .mbr-section-title {
  color: #014188;
}
.cid-s1pjoZcs1G {
  padding-top: 0rem;
  padding-bottom: 0rem;
  overflow: hidden;
  background-color: #ffffff;
}
.cid-s1pjoZcs1G .date__block {
  margin-bottom: 17px;
}
.cid-s1pjoZcs1G .date__article {
  display: inline-block;
  margin: 0;
  padding: 0 7.5px;
  color: #fe5925;
}
.cid-s1pjoZcs1G .dot {
  display: inline-block;
  vertical-align: super;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #014188;
}
.cid-s1pjoZcs1G .date__d {
  display: inline-block;
  margin: 0;
  padding: 0 7.5px;
  color: #689bb2;
}
.cid-s1pjoZcs1G .title__block {
  position: relative;
  z-index: 1;
  padding-top: 40px;
  padding-bottom: 91px;
}
.cid-s1pjoZcs1G .mbr-section-title {
  margin-bottom: 18px;
}
.cid-s1pjoZcs1G .mbr-text {
  margin-bottom: 20px;
}
.cid-s1pjoZcs1G .link {
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
}
.cid-s1pjoZcs1G .link a {
  font-weight: 700;
}
.cid-s1pjoZcs1G .link span {
  display: inline-block !important;
  transition: all 0.3s ease;
  margin-left: 13px;
}
.cid-s1pjoZcs1G .link:hover span {
  transform: translateX(9px);
}
.cid-s1pjoZcs1G .image__block {
  margin-bottom: -144px;
  padding-top: 121px;
  position: relative;
}
.cid-s1pjoZcs1G .image__block:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background-color: #ffffff;
}
.cid-s1pjoZcs1G .image {
  position: relative;
  width: 100%;
  z-index: 1;
}
.cid-s1pjoZcs1G .image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 575px;
  background-color: #ececec;
}
.cid-s1pjoZcs1G .image img {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translatex(-50%);
}
.cid-s1pjoZcs1G .content__wrap {
  position: relative;
  z-index: 2;
  margin-bottom: 95px;
}
.cid-s1pjoZcs1G .content__wrap:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 15px;
  right: 15px;
  height: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #ececec;
}
@media (max-width: 991px) {
  .cid-s1pjoZcs1G .title__block {
    padding: 25px 30px 55px;
  }
  .cid-s1pjoZcs1G .content__wrap {
    margin-bottom: 90px;
  }
  .cid-s1pjoZcs1G .date__block {
    padding-top: 22px;
    margin-bottom: 17px;
  }
  .cid-s1pjoZcs1G .mbr-section-title {
    margin-bottom: 15px;
  }
  .cid-s1pjoZcs1G .mbr-text {
    margin-bottom: 11px;
  }
  .cid-s1pjoZcs1G .image__block {
    min-height: 150px;
    padding-top: 68px;
    margin-bottom: 0;
  }
  .cid-s1pjoZcs1G .image:before {
    padding-top: 375px;
  }
}
.cid-s1pjoZcs1G .mbr-section-title,
.cid-s1pjoZcs1G .date__block {
  color: #014188;
}
.cid-s1pjoZcs1G .mbr-text,
.cid-s1pjoZcs1G .link {
  color: #4c4c4c;
}
.cid-s1pjqGvPdO {
  padding-top: 0rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.cid-s1pjqGvPdO .mbr-section-title {
  margin-bottom: 26px;
  font-weight: 300;
  color: #014188;
}
.cid-s1pjqGvPdO .mbr-section-title b,
.cid-s1pjqGvPdO .mbr-section-title strong {
  font-weight: 500;
}
.cid-s1pjqGvPdO .mbr-text {
  margin-bottom: 18px;
}
.cid-s1pjqGvPdO .row__image {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.cid-s1pjqGvPdO .row__image:nth-child(1) {
  padding-right: 80px;
}
.cid-s1pjqGvPdO .row__image:nth-child(2) {
  padding-left: 80px;
}
.cid-s1pjqGvPdO .image {
  margin-right: 13px;
  width: 100%;
}
.cid-s1pjqGvPdO .image img {
  max-width: 100%;
  object-fit: cover;
  max-height: 245px;
}
.cid-s1pjqGvPdO .link {
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
}
.cid-s1pjqGvPdO .link a {
  font-weight: 700;
}
.cid-s1pjqGvPdO .link span {
  display: inline-block !important;
  transition: all 0.3s ease;
  margin-left: 13px;
}
.cid-s1pjqGvPdO .link:hover span {
  transform: translateX(9px);
}
@media (max-width: 991px) {
  .cid-s1pjqGvPdO .row__image {
    margin-bottom: 9px;
  }
  .cid-s1pjqGvPdO .row__image:nth-child(1) {
    padding-right: 32px;
  }
  .cid-s1pjqGvPdO .row__image:nth-child(2) {
    padding-left: 32px;
  }
  .cid-s1pjqGvPdO .title__block {
    margin-top: 60px;
  }
  .cid-s1pjqGvPdO .image {
    margin-right: 10px;
    height: auto;
    max-height: initial;
  }
  .cid-s1pjqGvPdO .mbr-section-title {
    margin-bottom: 19px;
  }
  .cid-s1pjqGvPdO .mbr-text {
    margin-bottom: 11px;
  }
}
.cid-s1pjqGvPdO .mbr-text,
.cid-s1pjqGvPdO .link {
  color: #4c4c4c;
}
.cid-s1pjqgvBMN {
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #ffffff;
}
.cid-s1pjqgvBMN .title__block {
  margin-bottom: 89px;
}
.cid-s1pjqgvBMN .timelines-container .separline:last-child:before {
  content: none;
}
.cid-s1pjqgvBMN .mbr-timeline-title {
  margin-bottom: 10px;
  font-weight: 500;
  color: #014188;
}
.cid-s1pjqgvBMN .timeline-text-content {
  padding: 27px 27px 28px 37px;
  border: 1px solid #014188;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
}
.cid-s1pjqgvBMN .timeline-text-content p {
  margin-bottom: 0;
}
.cid-s1pjqgvBMN .timeline-text-content:before {
  content: "";
  width: 14px;
  height: 14px;
  border: 1px solid #014188;
  display: block;
  border-bottom: none;
  border-left: none;
  position: absolute;
  right: -8px;
  top: 18px;
  background-color: #ffffff;
  transform: rotate(45deg);
}
.cid-s1pjqgvBMN .timeline-element {
  margin-top: 20px;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
}
.cid-s1pjqgvBMN .timeline-element:nth-child(odd) .timeline-text-content {
  margin-right: 35px;
}
.cid-s1pjqgvBMN .timeline-element:nth-child(even) .timeline-text-content {
  margin-left: 35px;
}
.cid-s1pjqgvBMN
  .timeline-element:nth-child(even)
  .timeline-text-content:before {
  right: auto;
  left: -8px;
  top: 18px;
  transform: rotate(225deg);
}
.cid-s1pjqgvBMN .iconBackground {
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 21px;
  width: 10px;
  height: 10px;
  transform: translateX(-50%);
  background-color: #f7941e;
}
.cid-s1pjqgvBMN .iconBackground:before {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: inherit;
  opacity: 0.35;
  top: -10px;
  left: -10px;
  border-radius: 50%;
}
.cid-s1pjqgvBMN .separline:before {
  top: 60px;
  bottom: 0;
  position: absolute;
  content: "";
  width: 0.5px;
  background-color: #f7941e;
  left: calc(50% - 1px);
  height: calc(100% - 3rem);
  opacity: 0.35;
}
@media (max-width: 991px) {
  .cid-s1pjqgvBMN .title__block {
    margin-bottom: 40px;
  }
  .cid-s1pjqgvBMN .timeline-element {
    margin: 0 0 28px 10px;
  }
  .cid-s1pjqgvBMN .timeline-element:nth-child(odd) .timeline-text-content {
    margin-right: 10px;
  }
  .cid-s1pjqgvBMN
    .timeline-element:nth-child(odd)
    .timeline-text-content:before {
    right: auto;
    left: -8px;
    transform: rotate(225deg);
  }
  .cid-s1pjqgvBMN .timeline-element:nth-child(even) .timeline-text-content {
    margin-right: 10px;
  }
  .cid-s1pjqgvBMN .timeline-text-content {
    padding: 18px 19px;
    margin-left: 0 !important;
  }
  .cid-s1pjqgvBMN .iconBackground {
    left: 0 !important;
  }
  .cid-s1pjqgvBMN .separline:before {
    left: 0 !important;
  }
}
.cid-s1pjqgvBMN .mbr-section-title {
  color: #014188;
}
.cid-s1pjqgvBMN .mbr-timeline-text {
  color: #4c4c4c;
}
.cid-s1pjrJOPc9 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #ffffff;
}
.cid-s1pjrJOPc9 .subscr__title {
  padding: 7px 7px 7px 0;
  margin-bottom: 0;
  border-right: 1px solid #0271ed;
  text-align: center;
  color: #014188;
}
.cid-s1pjrJOPc9 .mbr-text {
  padding-right: 70px;
  color: #014188;
}
.cid-s1pjrJOPc9 .form-group {
  padding: 0;
}
.cid-s1pjrJOPc9 .form-control {
  background-color: #ffffff !important;
  color: #f7941e;
  border: 1px solid #f7941e;
  padding: 21px 18px;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.cid-s1pjrJOPc9 .input-group-btn {
  padding: 0;
  left: -1px;
}
.cid-s1pjrJOPc9 .input-group-btn .btn {
  width: 100%;
  height: 70px;
  font-weight: 500;
}
.cid-s1pjrJOPc9 .mbr-form .btn {
  margin: 0;
}
.cid-s1pjrJOPc9 .mbr-form .input-group-btn .btn {
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.cid-s1pjrJOPc9 input::-webkit-input-placeholder {
  color: #f7941e;
  opacity: 0.8;
}
.cid-s1pjrJOPc9 input::-moz-placeholder {
  color: #f7941e;
  opacity: 0.8;
}
@media (max-width: 1199px) {
  .cid-s1pjrJOPc9 .subscr__title {
    padding: 5px 0;
    border: none;
  }
  .cid-s1pjrJOPc9 .mbr-text {
    padding-right: 0;
    padding-left: 28px;
    border-left: 1px solid #0271ed;
  }
  .cid-s1pjrJOPc9 .subscribe__form {
    margin-top: 30px;
  }
}
@media (max-width: 991px) {
  .cid-s1pjrJOPc9 .dragArea {
    margin: 0;
  }
  .cid-s1pjrJOPc9 .form-control {
    font-size: 16px;
    padding: 11px 18px;
    height: 50px;
  }
  .cid-s1pjrJOPc9 .input-group-btn {
    padding: 0;
    left: -1px;
  }
  .cid-s1pjrJOPc9 .input-group-btn .btn {
    height: 50px;
    padding: 16px 0;
    font-size: 16px;
    line-height: 18px;
    min-width: auto;
  }
}
@media (max-width: 575px) {
  .cid-s1pjrJOPc9 .mbr-text {
    font-size: 14px;
    line-height: 1.29;
  }
}
.cid-s1pjs404NI {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: #ececec;
}
.cid-s1pjs404NI .nav__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
}
.cid-s1pjs404NI .menu__footer {
  width: 50%;
  padding-right: 40px;
  margin-bottom: 30px;
}
.cid-s1pjs404NI .mbr-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cid-s1pjs404NI .menu__item {
  margin-bottom: 20px;
}
.cid-s1pjs404NI .menu__item a {
  font-weight: 600;
}
.cid-s1pjs404NI .widget__title {
  line-height: 1.17;
  margin-bottom: 20px;
  color: #f7941e;
}
.cid-s1pjs404NI .widget__feed {
  max-width: 305px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cid-s1pjs404NI .widget__item {
  display: block;
  width: 92px;
  height: 92px;
  margin-bottom: 13px;
}
.cid-s1pjs404NI .widget__item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .cid-s1pjs404NI .menu__item {
    text-align: center;
  }
  .cid-s1pjs404NI .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cid-saI3SlNkeh {
  min-height: 114px !important;
  position: relative;
  overflow: visible;
  background: #ffffff;
}
.cid-saI3SlNkeh .nav-link,
.cid-saI3SlNkeh .navbar-caption {
  font-weight: 700;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh .navbar-dropdown .navbar-brand span {
  vertical-align: 0;
}
.cid-saI3SlNkeh .container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.cid-saI3SlNkeh .nav-link.text-primary:hover,
.cid-saI3SlNkeh .nav-link.text-primary:focus {
  color: #014188 !important;
}
.cid-saI3SlNkeh .nav-link {
  position: relative;
  line-height: 1;
  margin: 0 !important;
  padding: 10px 20px !important;
}
.cid-saI3SlNkeh .nav-link:hover:before {
  opacity: 1;
}
.cid-saI3SlNkeh .nav-link:before {
  content: "";
  bottom: 0;
  width: calc(100% - 36px);
  left: 18px;
  height: 3px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #dddddd;
  transition: opacity 0.3s linear;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .nav-link {
    margin: 0 !important;
  }
}
.cid-saI3SlNkeh .icons-menu {
  line-height: 0;
}
.cid-saI3SlNkeh .icons__wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  font-size: 29px;
  margin-left: 15px;
}
.cid-saI3SlNkeh
  .navbar-nav
  .nav-item:nth-child(2)
  .dropdown-menu
  .dropdown-item {
  width: 247px;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar-nav .nav-item:nth-child(2) .dropdown-menu .dropdown {
    display: inline-block;
  }
}
.cid-saI3SlNkeh .dropdown-menu .menu__images {
  display: flex;
  transform: translateY(-11px);
}
.cid-saI3SlNkeh .dropdown-menu a {
  overflow: hidden;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours6-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image2 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours2-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image2:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image3 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours1-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image3:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-item {
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 10px 40px 10px 20px;
  line-height: 1;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}
@media (max-width: 1300px) {
  .cid-saI3SlNkeh
    .navbar-nav
    .nav-item:nth-child(2)
    .dropdown-menu
    .dropdown-item {
    width: 167px;
    padding: 10px;
    white-space: normal;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image1 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image2 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image3 {
    width: 170px;
    height: 140px;
  }
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh.dropdown.open
    > .dropdown-menu:not(.dropdown-submenu)
    > .dropdown
    > .dropdown-submenu {
    visibility: visible;
    opacity: 1;
  }
  .cid-saI3SlNkeh .mbr-section-btn {
    margin-left: 31px;
  }
  .cid-saI3SlNkeh .btn {
    white-space: nowrap;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    min-width: 210px;
    display: block;
    z-index: 5;
    background-color: #ffffff !important;
    border: 1px solid #ddebe9;
    border-radius: 4px;
    left: 17px;
    right: auto;
    top: 100%;
    opacity: 0;
    padding: 11px 0 !important;
    margin: 0 !important;
    visibility: hidden;
    transition: all 0.3s linear;
    transform: translateY(0);
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: translateY(20px);
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item.dropdown:hover::before,
  .cid-saI3SlNkeh .nav-item.dropdown.open:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
  }
  .is-builder .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1200px) {
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    align-items: center !important;
  }
}
.cid-saI3SlNkeh .navbar > .container,
.cid-saI3SlNkeh .navbar > .container-fluid {
  align-items: center;
}
.cid-saI3SlNkeh .dropdown-toggle::after,
.cid-saI3SlNkeh .link.dropdown-toggle:after {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 6px;
  content: "";
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  transition: all 0.25s ease 0s;
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-toggle:after {
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}
.cid-saI3SlNkeh .open > .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-bottom: -8px;
  transform: rotate(225deg);
}
.cid-saI3SlNkeh
  .dropdown.open
  .dropdown-toggle[aria-expanded="true"]
  + .dropdown-submenu {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.cid-saI3SlNkeh .nav-dropdown .dropdown-submenu {
  display: none;
  margin: 0 !important;
  font-weight: 400;
  top: 0 !important;
}
.cid-saI3SlNkeh .nav-item:focus,
.cid-saI3SlNkeh .nav-link:focus {
  outline: none;
}
.cid-saI3SlNkeh .dropdown .dropdown-menu .dropdown-item.dropdown-toggle:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown .dropdown-menu .dropdown-item {
  transition: none;
  margin: 0 !important;
}
.cid-saI3SlNkeh a.nav-link {
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh ul.navbar-nav {
  flex-wrap: wrap;
  flex: 1;
}
.cid-saI3SlNkeh .navbar {
  padding: 12px 52px 11px 43px;
  min-height: 114px;
  transition: all 0.3s;
  background: #ffffff;
}
.cid-saI3SlNkeh .navbar.opened {
  transition: all 0.3s;
  background: #ffffff !important;
}
.cid-saI3SlNkeh .navbar .navbar-collapse {
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.cid-saI3SlNkeh .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-saI3SlNkeh .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(100vh - 100px - 1rem);
  }
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu {
  background: transparent !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .dropdown-menu .menu__images {
    display: none;
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item .nav-link::before {
    display: none;
  }
  .cid-saI3SlNkeh.opened .dropdown-menu {
    top: 0;
  }
  .cid-saI3SlNkeh .mbr-section-btn .btn {
    min-width: auto;
    padding: 5px;
    font-size: 14px;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    margin: 0;
    padding: 0;
    line-height: 45px;
    border: 0;
    left: 8px;
    width: 100%;
    max-width: 100% !important;
    box-shadow: none;
  }
  .cid-saI3SlNkeh .dropdown-menu .dropdown-submenu {
    left: 0 !important;
    position: relative !important;
  }
  .cid-saI3SlNkeh .navbar-logo {
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar-logo img {
    height: 60px !important;
  }
  .cid-saI3SlNkeh .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    margin: 0 !important;
    padding: 0;
  }
  .cid-saI3SlNkeh .navbar-toggler {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .cid-saI3SlNkeh .icons-menu {
    margin: 30px 0;
    text-align: center;
  }
  .cid-saI3SlNkeh .navbar {
    padding: 13px 90px;
  }
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    position: absolute;
    top: 100%;
    background-color: #ffffff !important;
    padding-left: 20px;
    padding-right: 20px;
    left: 0px;
    right: 0px;
  }
  .cid-saI3SlNkeh .navbar .dropdown-menu .dropdown-item {
    justify-content: flex-start;
    padding: 15.5px 23px;
    line-height: 1;
  }
  .cid-saI3SlNkeh .dropdown-submenu .dropdown-item {
    padding-left: 45px !important;
  }
  .cid-saI3SlNkeh .dropdown-toggle::after,
  .cid-saI3SlNkeh .link.dropdown-toggle:after {
    position: absolute;
    right: 12px;
  }
  .cid-saI3SlNkeh .nav-item {
    border-bottom: 1px solid #ddebe9;
  }
  .cid-saI3SlNkeh a.nav-link {
    justify-content: flex-start;
    line-height: 21px;
    padding: 35px 43px 35px 19px !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 100px;
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar .dropdown.open > .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}
.cid-saI3SlNkeh.navbar-short {
  background: #ffffff !important;
  min-height: 70px;
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .nav-dropdown {
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .navbar-brand {
  padding: 0;
}
.cid-saI3SlNkeh.navbar-short .navbar-logo img {
  height: 70px !important;
}
@media (max-width: 767px) {
  .cid-saI3SlNkeh .navbar {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh a.nav-link {
    padding: 27px 26px 27px 19px !important;
  }
}
.cid-saI3SlNkeh .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 12px;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
}
.cid-saI3SlNkeh .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-saI3SlNkeh .dropdown-item.active,
.cid-saI3SlNkeh .dropdown-item:active {
  background-color: transparent;
}
.cid-saI3SlNkeh .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.cid-saI3SlNkeh
  .navbar.navbar-expand-lg
  .dropdown
  .dropdown-menu
  .dropdown-submenu {
  left: 100%;
  position: absolute;
}
.cid-saI3SlNkeh button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #f7941e;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.is-builder .cid-saI3SlNkeh .dropdown.open > .dropdown-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(20px);
}
.cid-saI3SlNkeh .navbar-dropdown {
  position: absolute;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-saI3SlNkeh .navbar {
    height: 97px;
  }
  .cid-saI3SlNkeh .navbar.opened {
    height: auto;
  }
  .cid-saI3SlNkeh .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-saI7Go1rhj {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #ffffff;
}
.cid-saI7Go1rhj .subscr__title {
  padding: 7px 7px 7px 0;
  margin-bottom: 0;
  border-right: 1px solid #0271ed;
}
.cid-saI7Go1rhj .mbr-text {
  padding-right: 70px;
}
.cid-saI7Go1rhj .form-group {
  padding: 0;
}
.cid-saI7Go1rhj .form-control {
  background-color: #ffffff !important;
  color: #689bb2;
  border: 1px solid #003d59;
  padding: 21px 18px;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.cid-saI7Go1rhj .input-group-btn {
  padding: 0;
  left: -1px;
}
.cid-saI7Go1rhj .input-group-btn .btn {
  width: 100%;
  height: 70px;
  font-weight: 500;
}
.cid-saI7Go1rhj .mbr-form .btn {
  margin: 0;
}
.cid-saI7Go1rhj .mbr-form .input-group-btn .btn {
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.cid-saI7Go1rhj input::-webkit-input-placeholder {
  color: #689bb2;
  opacity: 0.8;
}
.cid-saI7Go1rhj input::-moz-placeholder {
  color: #689bb2;
  opacity: 0.8;
}
@media (max-width: 1199px) {
  .cid-saI7Go1rhj .subscr__title {
    padding: 5px 0;
    border: none;
  }
  .cid-saI7Go1rhj .mbr-text {
    padding-right: 0;
    padding-left: 28px;
    border-left: 1px solid #0271ed;
  }
  .cid-saI7Go1rhj .subscribe__form {
    margin-top: 30px;
  }
}
@media (max-width: 991px) {
  .cid-saI7Go1rhj .dragArea {
    margin: 0;
  }
  .cid-saI7Go1rhj .form-control {
    font-size: 16px;
    padding: 11px 18px;
    height: 50px;
  }
  .cid-saI7Go1rhj .input-group-btn {
    padding: 0;
    left: -1px;
  }
  .cid-saI7Go1rhj .input-group-btn .btn {
    height: 50px;
    padding: 16px 0;
    font-size: 16px;
    line-height: 18px;
    min-width: auto;
  }
}
@media (max-width: 575px) {
  .cid-saI7Go1rhj .mbr-text {
    font-size: 14px;
    line-height: 1.29;
  }
}
.cid-saI3SnlaZp {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: #ececec;
}
.cid-saI3SnlaZp .nav__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
}
.cid-saI3SnlaZp .menu__footer {
  width: 50%;
  padding-right: 40px;
  margin-bottom: 30px;
}
.cid-saI3SnlaZp .mbr-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cid-saI3SnlaZp .menu__item {
  margin-bottom: 20px;
}
.cid-saI3SnlaZp .menu__item a {
  font-weight: 600;
}
.cid-saI3SnlaZp .widget__title {
  line-height: 1.17;
  margin-bottom: 20px;
  color: #f7941e;
}
.cid-saI3SnlaZp .widget__feed {
  max-width: 305px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cid-saI3SnlaZp .widget__item {
  display: block;
  width: 92px;
  height: 92px;
  margin-bottom: 13px;
}
.cid-saI3SnlaZp .widget__item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .cid-saI3SnlaZp .menu__item {
    text-align: center;
  }
  .cid-saI3SnlaZp .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cid-s1pgD8z0jj {
  min-height: 114px !important;
  position: relative;
  overflow: visible;
  background: #ffffff;
}
.cid-s1pgD8z0jj .nav-link,
.cid-s1pgD8z0jj .navbar-caption {
  font-weight: 700;
  transition: all 0.3s linear;
}
.cid-s1pgD8z0jj .navbar-dropdown .navbar-brand span {
  vertical-align: 0;
}
.cid-s1pgD8z0jj .container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.cid-s1pgD8z0jj .nav-link.text-primary:hover,
.cid-s1pgD8z0jj .nav-link.text-primary:focus {
  color: #014188 !important;
}
.cid-s1pgD8z0jj .nav-link {
  position: relative;
  line-height: 1;
  margin: 0 !important;
  padding: 10px 20px !important;
}
.cid-s1pgD8z0jj .nav-link:hover:before {
  opacity: 1;
}
.cid-s1pgD8z0jj .nav-link:before {
  content: "";
  bottom: 0;
  width: calc(100% - 36px);
  left: 18px;
  height: 3px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #dddddd;
  transition: opacity 0.3s linear;
}
@media (max-width: 991px) {
  .cid-s1pgD8z0jj .nav-link {
    margin: 0 !important;
  }
}
.cid-s1pgD8z0jj .icons-menu {
  line-height: 0;
}
.cid-s1pgD8z0jj .icons__wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  font-size: 29px;
  margin-left: 15px;
}
.cid-s1pgD8z0jj
  .navbar-nav
  .nav-item:nth-child(2)
  .dropdown-menu
  .dropdown-item {
  width: 247px;
}
@media (min-width: 992px) {
  .cid-s1pgD8z0jj .navbar-nav .nav-item:nth-child(2) .dropdown-menu .dropdown {
    display: inline-block;
  }
}
.cid-s1pgD8z0jj .dropdown-menu .menu__images {
  display: flex;
  transform: translateY(-11px);
}
.cid-s1pgD8z0jj .dropdown-menu a {
  overflow: hidden;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image1 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours6-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image1:hover {
  transform: scale(1.3);
}
.cid-s1pgD8z0jj .dropdown-menu a > .image2 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours2-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image2:hover {
  transform: scale(1.3);
}
.cid-s1pgD8z0jj .dropdown-menu a > .image3 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours1-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-s1pgD8z0jj .dropdown-menu a > .image3:hover {
  transform: scale(1.3);
}
.cid-s1pgD8z0jj .dropdown-menu .dropdown-item {
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 10px 40px 10px 20px;
  line-height: 1;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}
@media (max-width: 1300px) {
  .cid-s1pgD8z0jj
    .navbar-nav
    .nav-item:nth-child(2)
    .dropdown-menu
    .dropdown-item {
    width: 167px;
    padding: 10px;
    white-space: normal;
  }
  .cid-s1pgD8z0jj .dropdown-menu a > .image1 {
    width: 170px;
    height: 140px;
  }
  .cid-s1pgD8z0jj .dropdown-menu a > .image2 {
    width: 170px;
    height: 140px;
  }
  .cid-s1pgD8z0jj .dropdown-menu a > .image3 {
    width: 170px;
    height: 140px;
  }
}
@media (min-width: 992px) {
  .cid-s1pgD8z0jj.dropdown.open
    > .dropdown-menu:not(.dropdown-submenu)
    > .dropdown
    > .dropdown-submenu {
    visibility: visible;
    opacity: 1;
  }
  .cid-s1pgD8z0jj .mbr-section-btn {
    margin-left: 31px;
  }
  .cid-s1pgD8z0jj .btn {
    white-space: nowrap;
  }
  .cid-s1pgD8z0jj .dropdown-menu {
    min-width: 210px;
    display: block;
    z-index: 5;
    background-color: #ffffff !important;
    border: 1px solid #ddebe9;
    border-radius: 4px;
    left: 17px;
    right: auto;
    top: 100%;
    opacity: 0;
    padding: 11px 0 !important;
    margin: 0 !important;
    visibility: hidden;
    transition: all 0.3s linear;
    transform: translateY(0);
  }
  .cid-s1pgD8z0jj .nav-item:hover .nav-link + .dropdown-menu {
    transform: translateY(20px);
    opacity: 1;
    visibility: visible;
  }
  .cid-s1pgD8z0jj .nav-item.dropdown:hover::before,
  .cid-s1pgD8z0jj .nav-item.dropdown.open:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
  }
  .is-builder .cid-s1pgD8z0jj .nav-item:hover .nav-link + .dropdown-menu {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1200px) {
  .cid-s1pgD8z0jj .navbar .navbar-collapse {
    align-items: center !important;
  }
}
.cid-s1pgD8z0jj .navbar > .container,
.cid-s1pgD8z0jj .navbar > .container-fluid {
  align-items: center;
}
.cid-s1pgD8z0jj .dropdown-toggle::after,
.cid-s1pgD8z0jj .link.dropdown-toggle:after {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 6px;
  content: "";
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  transition: all 0.25s ease 0s;
}
.cid-s1pgD8z0jj .dropdown-menu .dropdown-toggle:after {
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}
.cid-s1pgD8z0jj .open > .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-bottom: -8px;
  transform: rotate(225deg);
}
.cid-s1pgD8z0jj
  .dropdown.open
  .dropdown-toggle[aria-expanded="true"]
  + .dropdown-submenu {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.cid-s1pgD8z0jj .nav-dropdown .dropdown-submenu {
  display: none;
  margin: 0 !important;
  font-weight: 400;
  top: 0 !important;
}
.cid-s1pgD8z0jj .nav-item:focus,
.cid-s1pgD8z0jj .nav-link:focus {
  outline: none;
}
.cid-s1pgD8z0jj .dropdown .dropdown-menu .dropdown-item.dropdown-toggle:before {
  display: none;
}
.cid-s1pgD8z0jj .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-s1pgD8z0jj .collapsed .dropdown .dropdown-menu .dropdown-item {
  transition: none;
  margin: 0 !important;
}
.cid-s1pgD8z0jj a.nav-link {
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
}
.cid-s1pgD8z0jj ul.navbar-nav {
  flex-wrap: wrap;
  flex: 1;
}
.cid-s1pgD8z0jj .navbar {
  padding: 12px 52px 11px 43px;
  min-height: 114px;
  transition: all 0.3s;
  background: #ffffff;
}
.cid-s1pgD8z0jj .navbar.opened {
  transition: all 0.3s;
  background: #ffffff !important;
}
.cid-s1pgD8z0jj .navbar .navbar-collapse {
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.cid-s1pgD8z0jj .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-s1pgD8z0jj .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-s1pgD8z0jj .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(100vh - 100px - 1rem);
  }
}
.cid-s1pgD8z0jj .navbar.collapsed .dropdown-menu {
  background: transparent !important;
}
.cid-s1pgD8z0jj .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-s1pgD8z0jj .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-s1pgD8z0jj .dropdown-menu .menu__images {
    display: none;
  }
  .cid-s1pgD8z0jj .nav-item:hover .nav-link + .dropdown-menu {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .cid-s1pgD8z0jj .nav-item .nav-link::before {
    display: none;
  }
  .cid-s1pgD8z0jj.opened .dropdown-menu {
    top: 0;
  }
  .cid-s1pgD8z0jj .mbr-section-btn .btn {
    min-width: auto;
    padding: 5px;
    font-size: 14px;
  }
  .cid-s1pgD8z0jj .dropdown-menu {
    margin: 0;
    padding: 0;
    line-height: 45px;
    border: 0;
    left: 8px;
    width: 100%;
    max-width: 100% !important;
    box-shadow: none;
  }
  .cid-s1pgD8z0jj .dropdown-menu .dropdown-submenu {
    left: 0 !important;
    position: relative !important;
  }
  .cid-s1pgD8z0jj .navbar-logo {
    margin: 0;
  }
  .cid-s1pgD8z0jj .navbar-logo img {
    height: 60px !important;
  }
  .cid-s1pgD8z0jj .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    margin: 0 !important;
    padding: 0;
  }
  .cid-s1pgD8z0jj .navbar-toggler {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .cid-s1pgD8z0jj .icons-menu {
    margin: 30px 0;
    text-align: center;
  }
  .cid-s1pgD8z0jj .navbar {
    padding: 13px 90px;
  }
  .cid-s1pgD8z0jj .navbar .navbar-collapse {
    position: absolute;
    top: 100%;
    background-color: #ffffff !important;
    padding-left: 20px;
    padding-right: 20px;
    left: 0px;
    right: 0px;
  }
  .cid-s1pgD8z0jj .navbar .dropdown-menu .dropdown-item {
    justify-content: flex-start;
    padding: 15.5px 23px;
    line-height: 1;
  }
  .cid-s1pgD8z0jj .dropdown-submenu .dropdown-item {
    padding-left: 45px !important;
  }
  .cid-s1pgD8z0jj .dropdown-toggle::after,
  .cid-s1pgD8z0jj .link.dropdown-toggle:after {
    position: absolute;
    right: 12px;
  }
  .cid-s1pgD8z0jj .nav-item {
    border-bottom: 1px solid #ddebe9;
  }
  .cid-s1pgD8z0jj a.nav-link {
    justify-content: flex-start;
    line-height: 21px;
    padding: 35px 43px 35px 19px !important;
  }
  .cid-s1pgD8z0jj ul.navbar-nav {
    padding: 0 100px;
    margin: 0;
  }
  .cid-s1pgD8z0jj .navbar .dropdown.open > .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}
.cid-s1pgD8z0jj.navbar-short {
  background: #ffffff !important;
  min-height: 70px;
  height: 70px;
}
.cid-s1pgD8z0jj.navbar-short .nav-dropdown {
  height: 70px;
}
.cid-s1pgD8z0jj.navbar-short .navbar-brand {
  padding: 0;
}
.cid-s1pgD8z0jj.navbar-short .navbar-logo img {
  height: 70px !important;
}
@media (max-width: 767px) {
  .cid-s1pgD8z0jj .navbar {
    padding: 0 !important;
  }
  .cid-s1pgD8z0jj ul.navbar-nav {
    padding: 0 !important;
  }
  .cid-s1pgD8z0jj a.nav-link {
    padding: 27px 26px 27px 19px !important;
  }
}
.cid-s1pgD8z0jj .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 12px;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
}
.cid-s1pgD8z0jj .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-s1pgD8z0jj .dropdown-item.active,
.cid-s1pgD8z0jj .dropdown-item:active {
  background-color: transparent;
}
.cid-s1pgD8z0jj .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.cid-s1pgD8z0jj
  .navbar.navbar-expand-lg
  .dropdown
  .dropdown-menu
  .dropdown-submenu {
  left: 100%;
  position: absolute;
}
.cid-s1pgD8z0jj button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #f7941e;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-s1pgD8z0jj button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-s1pgD8z0jj nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.is-builder .cid-s1pgD8z0jj .dropdown.open > .dropdown-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(20px);
}
.cid-s1pgD8z0jj .navbar-dropdown {
  position: absolute;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-s1pgD8z0jj .navbar {
    height: 97px;
  }
  .cid-s1pgD8z0jj .navbar.opened {
    height: auto;
  }
  .cid-s1pgD8z0jj .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-saBBbGBDVB {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #ffffff;
}
.cid-saBBbGBDVB .mbr-section-title {
  font-weight: 300;
}
.cid-saBBbGBDVB .mbr-section-title b,
.cid-saBBbGBDVB .mbr-section-title strong {
  font-weight: 500;
}
.cid-saBBbGBDVB .link {
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
}
.cid-saBBbGBDVB .link a {
  font-weight: 700;
}
.cid-saBBbGBDVB .link span {
  display: inline-block !important;
  transition: all 0.3s ease;
  margin-left: 13px;
}
.cid-saBBbGBDVB .link:hover span {
  transform: translateX(9px);
}
.cid-saBBbGBDVB .card__block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.cid-saBBbGBDVB .card__block:nth-child(2) .card__content::before {
  background-color: #fe5925;
}
.cid-saBBbGBDVB .card__block:nth-child(3) .card__content::before {
  background-color: #014188;
}
.cid-saBBbGBDVB .card__block:nth-child(4) .card__content::before {
  background-color: #f7941e;
}
.cid-saBBbGBDVB .card__block:nth-child(6) .card__content::before {
  background-color: #014188;
}
.cid-saBBbGBDVB .card__block:nth-child(7) .card__content::before {
  background-color: #4c4c4c;
}
.cid-saBBbGBDVB .card__block:nth-child(8) .card__content::before {
  background-color: #dddddd;
}
.cid-saBBbGBDVB .card__content {
  position: relative;
  width: 100%;
  padding: 20px 44px 25px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}
.cid-saBBbGBDVB .card__content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  z-index: 1;
}
.cid-saBBbGBDVB .card__content:hover {
  transform: scale(0.96, 0.96);
}
.cid-saBBbGBDVB .card__content .card__image {
  display: flex;
  height: 190px;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin-bottom: 10px;
}
.cid-saBBbGBDVB .card__content .card__image img {
  max-width: 145px;
}
.cid-saBBbGBDVB .card__content .card__title {
  margin-bottom: 7px;
  font-weight: 600;
  position: relative;
  z-index: 5;
}
.cid-saBBbGBDVB .card__content .card__text {
  margin-bottom: 0;
  position: relative;
  z-index: 5;
}
@media (max-width: 1024px) {
  .cid-saBBbGBDVB .title__block {
    padding: 24px 17px;
  }
  .cid-saBBbGBDVB .card__content {
    padding: 15px 13px 20px;
  }
  .cid-saBBbGBDVB .card__content .card__image {
    height: 73px;
  }
  .cid-saBBbGBDVB .card__content .card__image img {
    max-width: 75px;
  }
}
@media (max-width: 767px) {
  .cid-saBBbGBDVB .row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .cid-saBBbGBDVB .card__block {
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .cid-saBBbGBDVB .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cid-saBBbGBDVB .card__content .card__title {
    font-size: 17px;
    line-height: 21px;
  }
  .cid-saBBbGBDVB .card__content .card__text {
    font-size: 14px;
    line-height: 18px;
  }
}
.cid-s1pjs404NI {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: #ececec;
}
.cid-s1pjs404NI .nav__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
}
.cid-s1pjs404NI .menu__footer {
  width: 50%;
  padding-right: 40px;
  margin-bottom: 30px;
}
.cid-s1pjs404NI .mbr-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cid-s1pjs404NI .menu__item {
  margin-bottom: 20px;
}
.cid-s1pjs404NI .menu__item a {
  font-weight: 600;
}
.cid-s1pjs404NI .widget__title {
  line-height: 1.17;
  margin-bottom: 20px;
  color: #f7941e;
}
.cid-s1pjs404NI .widget__feed {
  max-width: 305px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cid-s1pjs404NI .widget__item {
  display: block;
  width: 92px;
  height: 92px;
  margin-bottom: 13px;
}
.cid-s1pjs404NI .widget__item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .cid-s1pjs404NI .menu__item {
    text-align: center;
  }
  .cid-s1pjs404NI .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cid-saI6QwEU2J {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #ffffff;
}
.cid-saI6QwEU2J .mbr-section-title {
  color: #fe5925;
}
.cid-saI6QwEU2J .figure {
  display: block;
  position: relative;
  z-index: 1;
}
.cid-saI6QwEU2J .figure:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 105px;
  height: 105px;
  left: 220px;
  top: 37px;
  background-image: url("compass.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.cid-saI6QwEU2J .card__wrap {
  margin-bottom: 50px;
  display: flex;
  border-bottom: 1px solid #cce4f0;
}
.cid-saI6QwEU2J .content__block .card__wrap:last-child {
  border-bottom: 0;
}
.cid-saI6QwEU2J .card__title {
  margin-bottom: 10px;
  font-weight: 600;
}
.cid-saI6QwEU2J .card__text {
  margin-bottom: 8px;
}
.cid-saI6QwEU2J .card__image {
  display: block;
  max-width: 190px;
  width: 100%;
  text-align: center;
}
.cid-saI6QwEU2J .card__image img {
  width: 55%;
  object-fit: cover;
}
.cid-saI6QwEU2J .card__content {
  display: block;
  width: 100%;
  margin-bottom: 50px;
}
.cid-saI6QwEU2J .count__items {
  padding-left: 16px;
  margin: 0;
}
.cid-saI6QwEU2J .count__items .item {
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .cid-saI6QwEU2J .mbr-section-title {
    margin-bottom: 44px;
    text-align: center;
  }
  .cid-saI6QwEU2J .figure:before {
    left: 10%;
    top: 2px;
    width: 55px;
    height: 55px;
  }
  .cid-saI6QwEU2J .card__wrap {
    margin-bottom: 39px;
  }
  .cid-saI6QwEU2J .card__content {
    margin-bottom: 39px;
  }
}
@media (max-width: 575px) {
  .cid-saI6QwEU2J .card__image {
    max-width: 77px;
  }
  .cid-saI6QwEU2J .card__image img {
    width: 75%;
  }
}
.cid-saI3SlNkeh {
  min-height: 114px !important;
  position: relative;
  overflow: visible;
  background: #ffffff;
}
.cid-saI3SlNkeh .nav-link,
.cid-saI3SlNkeh .navbar-caption {
  font-weight: 700;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh .navbar-dropdown .navbar-brand span {
  vertical-align: 0;
}
.cid-saI3SlNkeh .container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.cid-saI3SlNkeh .nav-link.text-primary:hover,
.cid-saI3SlNkeh .nav-link.text-primary:focus {
  color: #014188 !important;
}
.cid-saI3SlNkeh .nav-link {
  position: relative;
  line-height: 1;
  margin: 0 !important;
  padding: 10px 20px !important;
}
.cid-saI3SlNkeh .nav-link:hover:before {
  opacity: 1;
}
.cid-saI3SlNkeh .nav-link:before {
  content: "";
  bottom: 0;
  width: calc(100% - 36px);
  left: 18px;
  height: 3px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #dddddd;
  transition: opacity 0.3s linear;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .nav-link {
    margin: 0 !important;
  }
}
.cid-saI3SlNkeh .icons-menu {
  line-height: 0;
}
.cid-saI3SlNkeh .icons__wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  font-size: 29px;
  margin-left: 15px;
}
.cid-saI3SlNkeh
  .navbar-nav
  .nav-item:nth-child(2)
  .dropdown-menu
  .dropdown-item {
  width: 247px;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar-nav .nav-item:nth-child(2) .dropdown-menu .dropdown {
    display: inline-block;
  }
}
.cid-saI3SlNkeh .dropdown-menu .menu__images {
  display: flex;
  transform: translateY(-11px);
}
.cid-saI3SlNkeh .dropdown-menu a {
  overflow: hidden;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours6-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image2 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours2-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image2:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image3 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours1-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image3:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-item {
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 10px 40px 10px 20px;
  line-height: 1;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}
@media (max-width: 1300px) {
  .cid-saI3SlNkeh
    .navbar-nav
    .nav-item:nth-child(2)
    .dropdown-menu
    .dropdown-item {
    width: 167px;
    padding: 10px;
    white-space: normal;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image1 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image2 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image3 {
    width: 170px;
    height: 140px;
  }
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh.dropdown.open
    > .dropdown-menu:not(.dropdown-submenu)
    > .dropdown
    > .dropdown-submenu {
    visibility: visible;
    opacity: 1;
  }
  .cid-saI3SlNkeh .mbr-section-btn {
    margin-left: 31px;
  }
  .cid-saI3SlNkeh .btn {
    white-space: nowrap;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    min-width: 210px;
    display: block;
    z-index: 5;
    background-color: #ffffff !important;
    border: 1px solid #ddebe9;
    border-radius: 4px;
    left: 17px;
    right: auto;
    top: 100%;
    opacity: 0;
    padding: 11px 0 !important;
    margin: 0 !important;
    visibility: hidden;
    transition: all 0.3s linear;
    transform: translateY(0);
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: translateY(20px);
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item.dropdown:hover::before,
  .cid-saI3SlNkeh .nav-item.dropdown.open:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
  }
  .is-builder .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1200px) {
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    align-items: center !important;
  }
}
.cid-saI3SlNkeh .navbar > .container,
.cid-saI3SlNkeh .navbar > .container-fluid {
  align-items: center;
}
.cid-saI3SlNkeh .dropdown-toggle::after,
.cid-saI3SlNkeh .link.dropdown-toggle:after {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 6px;
  content: "";
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  transition: all 0.25s ease 0s;
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-toggle:after {
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}
.cid-saI3SlNkeh .open > .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-bottom: -8px;
  transform: rotate(225deg);
}
.cid-saI3SlNkeh
  .dropdown.open
  .dropdown-toggle[aria-expanded="true"]
  + .dropdown-submenu {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.cid-saI3SlNkeh .nav-dropdown .dropdown-submenu {
  display: none;
  margin: 0 !important;
  font-weight: 400;
  top: 0 !important;
}
.cid-saI3SlNkeh .nav-item:focus,
.cid-saI3SlNkeh .nav-link:focus {
  outline: none;
}
.cid-saI3SlNkeh .dropdown .dropdown-menu .dropdown-item.dropdown-toggle:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown .dropdown-menu .dropdown-item {
  transition: none;
  margin: 0 !important;
}
.cid-saI3SlNkeh a.nav-link {
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh ul.navbar-nav {
  flex-wrap: wrap;
  flex: 1;
}
.cid-saI3SlNkeh .navbar {
  padding: 12px 52px 11px 43px;
  min-height: 114px;
  transition: all 0.3s;
  background: #ffffff;
}
.cid-saI3SlNkeh .navbar.opened {
  transition: all 0.3s;
  background: #ffffff !important;
}
.cid-saI3SlNkeh .navbar .navbar-collapse {
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.cid-saI3SlNkeh .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-saI3SlNkeh .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(100vh - 100px - 1rem);
  }
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu {
  background: transparent !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .dropdown-menu .menu__images {
    display: none;
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item .nav-link::before {
    display: none;
  }
  .cid-saI3SlNkeh.opened .dropdown-menu {
    top: 0;
  }
  .cid-saI3SlNkeh .mbr-section-btn .btn {
    min-width: auto;
    padding: 5px;
    font-size: 14px;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    margin: 0;
    padding: 0;
    line-height: 45px;
    border: 0;
    left: 8px;
    width: 100%;
    max-width: 100% !important;
    box-shadow: none;
  }
  .cid-saI3SlNkeh .dropdown-menu .dropdown-submenu {
    left: 0 !important;
    position: relative !important;
  }
  .cid-saI3SlNkeh .navbar-logo {
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar-logo img {
    height: 60px !important;
  }
  .cid-saI3SlNkeh .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    margin: 0 !important;
    padding: 0;
  }
  .cid-saI3SlNkeh .navbar-toggler {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .cid-saI3SlNkeh .icons-menu {
    margin: 30px 0;
    text-align: center;
  }
  .cid-saI3SlNkeh .navbar {
    padding: 13px 90px;
  }
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    position: absolute;
    top: 100%;
    background-color: #ffffff !important;
    padding-left: 20px;
    padding-right: 20px;
    left: 0px;
    right: 0px;
  }
  .cid-saI3SlNkeh .navbar .dropdown-menu .dropdown-item {
    justify-content: flex-start;
    padding: 15.5px 23px;
    line-height: 1;
  }
  .cid-saI3SlNkeh .dropdown-submenu .dropdown-item {
    padding-left: 45px !important;
  }
  .cid-saI3SlNkeh .dropdown-toggle::after,
  .cid-saI3SlNkeh .link.dropdown-toggle:after {
    position: absolute;
    right: 12px;
  }
  .cid-saI3SlNkeh .nav-item {
    border-bottom: 1px solid #ddebe9;
  }
  .cid-saI3SlNkeh a.nav-link {
    justify-content: flex-start;
    line-height: 21px;
    padding: 35px 43px 35px 19px !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 100px;
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar .dropdown.open > .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}
.cid-saI3SlNkeh.navbar-short {
  background: #ffffff !important;
  min-height: 70px;
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .nav-dropdown {
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .navbar-brand {
  padding: 0;
}
.cid-saI3SlNkeh.navbar-short .navbar-logo img {
  height: 70px !important;
}
@media (max-width: 767px) {
  .cid-saI3SlNkeh .navbar {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh a.nav-link {
    padding: 27px 26px 27px 19px !important;
  }
}
.cid-saI3SlNkeh .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 12px;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
}
.cid-saI3SlNkeh .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-saI3SlNkeh .dropdown-item.active,
.cid-saI3SlNkeh .dropdown-item:active {
  background-color: transparent;
}
.cid-saI3SlNkeh .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.cid-saI3SlNkeh
  .navbar.navbar-expand-lg
  .dropdown
  .dropdown-menu
  .dropdown-submenu {
  left: 100%;
  position: absolute;
}
.cid-saI3SlNkeh button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #f7941e;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.is-builder .cid-saI3SlNkeh .dropdown.open > .dropdown-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(20px);
}
.cid-saI3SlNkeh .navbar-dropdown {
  position: absolute;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-saI3SlNkeh .navbar {
    height: 97px;
  }
  .cid-saI3SlNkeh .navbar.opened {
    height: auto;
  }
  .cid-saI3SlNkeh .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-saI3SnlaZp {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: #ececec;
}
.cid-saI3SnlaZp .nav__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
}
.cid-saI3SnlaZp .menu__footer {
  width: 50%;
  padding-right: 40px;
  margin-bottom: 30px;
}
.cid-saI3SnlaZp .mbr-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cid-saI3SnlaZp .menu__item {
  margin-bottom: 20px;
}
.cid-saI3SnlaZp .menu__item a {
  font-weight: 600;
}
.cid-saI3SnlaZp .widget__title {
  line-height: 1.17;
  margin-bottom: 20px;
  color: #f7941e;
}
.cid-saI3SnlaZp .widget__feed {
  max-width: 305px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cid-saI3SnlaZp .widget__item {
  display: block;
  width: 92px;
  height: 92px;
  margin-bottom: 13px;
}
.cid-saI3SnlaZp .widget__item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .cid-saI3SnlaZp .menu__item {
    text-align: center;
  }
  .cid-saI3SnlaZp .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cid-saI3SlNkeh {
  min-height: 114px !important;
  position: relative;
  overflow: visible;
  background: #ffffff;
}
.cid-saI3SlNkeh .nav-link,
.cid-saI3SlNkeh .navbar-caption {
  font-weight: 700;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh .navbar-dropdown .navbar-brand span {
  vertical-align: 0;
}
.cid-saI3SlNkeh .container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.cid-saI3SlNkeh .nav-link.text-primary:hover,
.cid-saI3SlNkeh .nav-link.text-primary:focus {
  color: #014188 !important;
}
.cid-saI3SlNkeh .nav-link {
  position: relative;
  line-height: 1;
  margin: 0 !important;
  padding: 10px 20px !important;
}
.cid-saI3SlNkeh .nav-link:hover:before {
  opacity: 1;
}
.cid-saI3SlNkeh .nav-link:before {
  content: "";
  bottom: 0;
  width: calc(100% - 36px);
  left: 18px;
  height: 3px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #dddddd;
  transition: opacity 0.3s linear;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .nav-link {
    margin: 0 !important;
  }
}
.cid-saI3SlNkeh .icons-menu {
  line-height: 0;
}
.cid-saI3SlNkeh .icons__wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  font-size: 29px;
  margin-left: 15px;
}
.cid-saI3SlNkeh
  .navbar-nav
  .nav-item:nth-child(2)
  .dropdown-menu
  .dropdown-item {
  width: 247px;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar-nav .nav-item:nth-child(2) .dropdown-menu .dropdown {
    display: inline-block;
  }
}
.cid-saI3SlNkeh .dropdown-menu .menu__images {
  display: flex;
  transform: translateY(-11px);
}
.cid-saI3SlNkeh .dropdown-menu a {
  overflow: hidden;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours6-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image2 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours2-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image2:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image3 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours1-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image3:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-item {
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 10px 40px 10px 20px;
  line-height: 1;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}
@media (max-width: 1300px) {
  .cid-saI3SlNkeh
    .navbar-nav
    .nav-item:nth-child(2)
    .dropdown-menu
    .dropdown-item {
    width: 167px;
    padding: 10px;
    white-space: normal;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image1 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image2 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image3 {
    width: 170px;
    height: 140px;
  }
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh.dropdown.open
    > .dropdown-menu:not(.dropdown-submenu)
    > .dropdown
    > .dropdown-submenu {
    visibility: visible;
    opacity: 1;
  }
  .cid-saI3SlNkeh .mbr-section-btn {
    margin-left: 31px;
  }
  .cid-saI3SlNkeh .btn {
    white-space: nowrap;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    min-width: 210px;
    display: block;
    z-index: 5;
    background-color: #ffffff !important;
    border: 1px solid #ddebe9;
    border-radius: 4px;
    left: 17px;
    right: auto;
    top: 100%;
    opacity: 0;
    padding: 11px 0 !important;
    margin: 0 !important;
    visibility: hidden;
    transition: all 0.3s linear;
    transform: translateY(0);
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: translateY(20px);
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item.dropdown:hover::before,
  .cid-saI3SlNkeh .nav-item.dropdown.open:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
  }
  .is-builder .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1200px) {
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    align-items: center !important;
  }
}
.cid-saI3SlNkeh .navbar > .container,
.cid-saI3SlNkeh .navbar > .container-fluid {
  align-items: center;
}
.cid-saI3SlNkeh .dropdown-toggle::after,
.cid-saI3SlNkeh .link.dropdown-toggle:after {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 6px;
  content: "";
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  transition: all 0.25s ease 0s;
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-toggle:after {
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}
.cid-saI3SlNkeh .open > .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-bottom: -8px;
  transform: rotate(225deg);
}
.cid-saI3SlNkeh
  .dropdown.open
  .dropdown-toggle[aria-expanded="true"]
  + .dropdown-submenu {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.cid-saI3SlNkeh .nav-dropdown .dropdown-submenu {
  display: none;
  margin: 0 !important;
  font-weight: 400;
  top: 0 !important;
}
.cid-saI3SlNkeh .nav-item:focus,
.cid-saI3SlNkeh .nav-link:focus {
  outline: none;
}
.cid-saI3SlNkeh .dropdown .dropdown-menu .dropdown-item.dropdown-toggle:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown .dropdown-menu .dropdown-item {
  transition: none;
  margin: 0 !important;
}
.cid-saI3SlNkeh a.nav-link {
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh ul.navbar-nav {
  flex-wrap: wrap;
  flex: 1;
}
.cid-saI3SlNkeh .navbar {
  padding: 12px 52px 11px 43px;
  min-height: 114px;
  transition: all 0.3s;
  background: #ffffff;
}
.cid-saI3SlNkeh .navbar.opened {
  transition: all 0.3s;
  background: #ffffff !important;
}
.cid-saI3SlNkeh .navbar .navbar-collapse {
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.cid-saI3SlNkeh .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-saI3SlNkeh .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(100vh - 100px - 1rem);
  }
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu {
  background: transparent !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .dropdown-menu .menu__images {
    display: none;
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item .nav-link::before {
    display: none;
  }
  .cid-saI3SlNkeh.opened .dropdown-menu {
    top: 0;
  }
  .cid-saI3SlNkeh .mbr-section-btn .btn {
    min-width: auto;
    padding: 5px;
    font-size: 14px;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    margin: 0;
    padding: 0;
    line-height: 45px;
    border: 0;
    left: 8px;
    width: 100%;
    max-width: 100% !important;
    box-shadow: none;
  }
  .cid-saI3SlNkeh .dropdown-menu .dropdown-submenu {
    left: 0 !important;
    position: relative !important;
  }
  .cid-saI3SlNkeh .navbar-logo {
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar-logo img {
    height: 60px !important;
  }
  .cid-saI3SlNkeh .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    margin: 0 !important;
    padding: 0;
  }
  .cid-saI3SlNkeh .navbar-toggler {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .cid-saI3SlNkeh .icons-menu {
    margin: 30px 0;
    text-align: center;
  }
  .cid-saI3SlNkeh .navbar {
    padding: 13px 90px;
  }
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    position: absolute;
    top: 100%;
    background-color: #ffffff !important;
    padding-left: 20px;
    padding-right: 20px;
    left: 0px;
    right: 0px;
  }
  .cid-saI3SlNkeh .navbar .dropdown-menu .dropdown-item {
    justify-content: flex-start;
    padding: 15.5px 23px;
    line-height: 1;
  }
  .cid-saI3SlNkeh .dropdown-submenu .dropdown-item {
    padding-left: 45px !important;
  }
  .cid-saI3SlNkeh .dropdown-toggle::after,
  .cid-saI3SlNkeh .link.dropdown-toggle:after {
    position: absolute;
    right: 12px;
  }
  .cid-saI3SlNkeh .nav-item {
    border-bottom: 1px solid #ddebe9;
  }
  .cid-saI3SlNkeh a.nav-link {
    justify-content: flex-start;
    line-height: 21px;
    padding: 35px 43px 35px 19px !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 100px;
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar .dropdown.open > .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}
.cid-saI3SlNkeh.navbar-short {
  background: #ffffff !important;
  min-height: 70px;
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .nav-dropdown {
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .navbar-brand {
  padding: 0;
}
.cid-saI3SlNkeh.navbar-short .navbar-logo img {
  height: 70px !important;
}
@media (max-width: 767px) {
  .cid-saI3SlNkeh .navbar {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh a.nav-link {
    padding: 27px 26px 27px 19px !important;
  }
}
.cid-saI3SlNkeh .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 12px;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
}
.cid-saI3SlNkeh .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-saI3SlNkeh .dropdown-item.active,
.cid-saI3SlNkeh .dropdown-item:active {
  background-color: transparent;
}
.cid-saI3SlNkeh .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.cid-saI3SlNkeh
  .navbar.navbar-expand-lg
  .dropdown
  .dropdown-menu
  .dropdown-submenu {
  left: 100%;
  position: absolute;
}
.cid-saI3SlNkeh button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #f7941e;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.is-builder .cid-saI3SlNkeh .dropdown.open > .dropdown-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(20px);
}
.cid-saI3SlNkeh .navbar-dropdown {
  position: absolute;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-saI3SlNkeh .navbar {
    height: 97px;
  }
  .cid-saI3SlNkeh .navbar.opened {
    height: auto;
  }
  .cid-saI3SlNkeh .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-saI4593aYg {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #ffffff;
  overflow: hidden;
  box-sizing: border-box;
}
.cid-saI4593aYg .mbr-section-title {
  margin-bottom: 25px;
}
.cid-saI4593aYg .mbr-section-subtitle {
  margin-bottom: 88px;
  font-weight: 400;
}
.cid-saI4593aYg .image {
  position: relative;
  z-index: 0;
  top: -50px;
}
.cid-saI4593aYg .image img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  max-height: 410px;
  object-fit: cover;
}
.cid-saI4593aYg .mbr-gallery-filter {
  text-align: left;
  padding: 0 20px;
  border-bottom: 1px solid #cce4f0;
}
.cid-saI4593aYg .mbr-gallery-filter .btn {
  padding: 0 20px 40px;
  margin: 0;
  margin-right: 35px;
  color: #003d59 !important;
  font-weight: 700;
  text-align: left;
  background: transparent !important;
  position: relative;
  border: none;
  min-width: auto;
}
.cid-saI4593aYg .mbr-gallery-filter ul {
  display: block;
}
.cid-saI4593aYg .mbr-gallery-filter ul li {
  position: relative;
  padding: 0;
}
.cid-saI4593aYg .mbr-gallery-filter li:not(.active) .btn.active:after,
.cid-saI4593aYg .mbr-gallery-filter li.active .btn:not(.active):after,
.cid-saI4593aYg .mbr-gallery-filter .mbr-gallery-filter .btn:hover:after {
  content: "";
  position: absolute;
  left: 0;
  right: auto;
  width: 100%;
  bottom: 0;
  height: 11px;
  background-color: #46877e;
  transition: all 0.3s ease;
}
.cid-saI4593aYg .mbr-gallery-row {
  margin-top: 50px;
}
.cid-saI4593aYg .mbr-slider .carousel-control {
  background: #1b1b1b;
}
.cid-saI4593aYg .mbr-slider .carousel-control-prev {
  left: 0;
  margin-left: 2.5rem;
  border-radius: 4px;
}
.cid-saI4593aYg .mbr-slider .carousel-control-next {
  right: 0;
  margin-right: 2.5rem;
  border-radius: 4px;
}
.cid-saI4593aYg .mbr-slider .modal-body .close {
  background: #1b1b1b;
}
.cid-saI4593aYg .mbr-gallery-item > div::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #003d59;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}
.cid-saI4593aYg .mbr-gallery-item > div:hover::before {
  opacity: 0 !important;
}
.cid-saI4593aYg .mbr-gallery-title {
  transition: all 0.3s;
  position: absolute;
  display: block;
  font-weight: 700;
  width: 100%;
  bottom: 30px;
  left: 0;
  text-align: center;
  padding: 0 1rem;
  color: #fff;
  background: transparent;
}
.cid-saI4593aYg .icon-focus {
  transition: all 0.3s;
  top: 30%;
  width: 48px;
  height: 48px;
  transform: translateY(-30%);
}
.cid-saI4593aYg .mbr-gallery-item > div img,
.cid-saI4593aYg .mbr-gallery-item div:before {
  border-radius: 4px;
  height: 255px;
}
.cid-saI4593aYg .mbr-gallery-item > div {
  transition: all 0.3s;
  border-radius: 4px;
}
.cid-saI4593aYg .mbr-gallery-item:hover > div {
  transform: scale(0.95);
}
.cid-saI4593aYg .carousel-control-prev .mbri-left:before {
  content: "\e90a";
}
.cid-saI4593aYg .carousel-control-next .mbri-right:before {
  content: "\e909";
}
.cid-saI4593aYg .modal-body a.close {
  border-radius: 0;
  font-size: 32px;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
  line-height: 70px;
}
@media (max-width: 991px) {
  .cid-saI4593aYg .mbr-section-subtitle {
    margin-bottom: 20px;
    text-align: center;
  }
  .cid-saI4593aYg .mbr-section-title {
    text-align: center;
  }
  .cid-saI4593aYg .image {
    top: auto;
    height: 420px;
  }
  .cid-saI4593aYg .mbr-gallery-filter .btn {
    padding: 0 0 22px;
  }
  .cid-saI4593aYg .mbr-gallery-filter ul li {
    margin-bottom: 10px;
  }
  .cid-saI4593aYg .mbr-gallery-item > div img,
  .cid-saI4593aYg .mbr-gallery-item div:before {
    height: auto;
    border-radius: 3px;
  }
  .cid-saI4593aYg .mbr-gallery-item > div {
    border-radius: 3px;
  }
}
@media (max-width: 767px) {
  .cid-saI4593aYg .image {
    height: 290px;
  }
}
.cid-saI3SnlaZp {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: #ececec;
}
.cid-saI3SnlaZp .nav__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
}
.cid-saI3SnlaZp .menu__footer {
  width: 50%;
  padding-right: 40px;
  margin-bottom: 30px;
}
.cid-saI3SnlaZp .mbr-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cid-saI3SnlaZp .menu__item {
  margin-bottom: 20px;
}
.cid-saI3SnlaZp .menu__item a {
  font-weight: 600;
}
.cid-saI3SnlaZp .widget__title {
  line-height: 1.17;
  margin-bottom: 20px;
  color: #f7941e;
}
.cid-saI3SnlaZp .widget__feed {
  max-width: 305px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cid-saI3SnlaZp .widget__item {
  display: block;
  width: 92px;
  height: 92px;
  margin-bottom: 13px;
}
.cid-saI3SnlaZp .widget__item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .cid-saI3SnlaZp .menu__item {
    text-align: center;
  }
  .cid-saI3SnlaZp .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cid-saI7ZslBbg {
  padding-top: 8rem;
  padding-bottom: 8rem;
  overflow: hidden;
  background-color: #ffffff;
}
.cid-saI7ZslBbg .mbr-text {
  margin-top: 20px;
  margin-bottom: 50px;
  padding-right: 70px;
}
.cid-saI7ZslBbg .title__block {
  padding-left: 96px;
  margin-bottom: 20px;
}
.cid-saI7ZslBbg .title__block .mbr-section-title {
  display: inline-block;
  margin-bottom: 25px;
}
.cid-saI7ZslBbg .figure {
  display: block;
  position: relative;
  z-index: 1;
}
.cid-saI7ZslBbg .figure:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 119px;
  height: 119px;
  bottom: -90px;
  right: -95px;
  background-image: url("compass.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.cid-saI7ZslBbg .carousel-item {
  justify-content: center;
  text-align: left;
}
.cid-saI7ZslBbg .carousel-item .media-container-row {
  flex-grow: 1;
}
.cid-saI7ZslBbg .carousel-item .slider__item {
  margin-right: 30px;
  position: relative;
  border-radius: 4px;
  border: 2px solid transparent;
  opacity: 1;
  transition: border 0.3s linear;
}
.cid-saI7ZslBbg .carousel-item .slider__item:hover {
  border: 2px solid #014188;
}
.cid-saI7ZslBbg .carousel-item .slider__item:hover .slider__content {
  border: 1px solid transparent;
}
.cid-saI7ZslBbg .carousel-item .slider__item .slider__image {
  width: 100%;
}
.cid-saI7ZslBbg .carousel-item .slider__item .slider__image img {
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cid-saI7ZslBbg .slider__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 320px;
  position: relative;
  padding: 30px 40px 32px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #ddebe9;
  border-top: 1px solid transparent;
  transition: border 0.3s;
}
.cid-saI7ZslBbg .slider__content .slider__content-wrap {
  margin-bottom: 38px;
  min-height: 210px;
}
.cid-saI7ZslBbg .slider__content .slider__subtitle {
  margin-bottom: 8px;
  color: #014188;
}
.cid-saI7ZslBbg .slider__content .slider__title {
  margin-bottom: 13px;
}
.cid-saI7ZslBbg .slider__content .slider__text {
  margin-bottom: 0;
}
.cid-saI7ZslBbg .link {
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
}
.cid-saI7ZslBbg .link a {
  font-weight: 700;
}
.cid-saI7ZslBbg .link span {
  display: inline-block !important;
  transition: all 0.3s ease;
  margin-left: 13px;
}
.cid-saI7ZslBbg .link:hover span {
  transform: translateX(9px);
}
.cid-saI7ZslBbg .carousel-item.active,
.cid-saI7ZslBbg .carousel-item-next,
.cid-saI7ZslBbg .carousel-item-prev {
  display: flex;
}
.cid-saI7ZslBbg .mbr-iconfont {
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cid-saI7ZslBbg .carousel-controls a {
  transition: all 0.3s;
  border-radius: 50%;
  border: 2px solid #003d59;
  background-color: transparent;
}
.cid-saI7ZslBbg .carousel-controls a span {
  color: #003d59;
  font-size: 18px;
}
.cid-saI7ZslBbg .carousel-controls a:hover .mobi-mbri-left {
  transform: translateX(-5px);
}
.cid-saI7ZslBbg .carousel-controls a:hover .mobi-mbri-right {
  transform: translateX(5px);
}
.cid-saI7ZslBbg .carousel-controls .carousel-control-next,
.cid-saI7ZslBbg .carousel-controls .carousel-control-prev {
  opacity: 1;
  width: 44px;
  height: 44px;
}
.cid-saI7ZslBbg .carousel-controls .carousel-control-next {
  right: -40px;
  bottom: auto;
  top: 44%;
}
.cid-saI7ZslBbg .carousel-controls .carousel-control-prev {
  left: -70px;
  bottom: auto;
  top: 44%;
}
.cid-saI7ZslBbg .cloneditem-1,
.cid-saI7ZslBbg .cloneditem-2,
.cid-saI7ZslBbg .cloneditem-3,
.cid-saI7ZslBbg .cloneditem-4,
.cid-saI7ZslBbg .cloneditem-5 {
  display: none;
}
.cid-saI7ZslBbg .col-lg-15 {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
}
@media (min-width: 992px) {
  .cid-saI7ZslBbg .col-lg-15 {
    flex: 0 0 20%;
    max-width: 20%;
    width: 20%;
  }
  .cid-saI7ZslBbg .carousel-inner {
    padding-bottom: 3px;
  }
  .cid-saI7ZslBbg
    .carousel-inner.slides2
    > .carousel-item.active.carousel-item-right,
  .cid-saI7ZslBbg .carousel-inner.slides2 > .carousel-item.carousel-item-next {
    left: 0;
    transform: translate3d(50%, 0, 0);
  }
  .cid-saI7ZslBbg
    .carousel-inner.slides2
    > .carousel-item.active.carousel-item-left,
  .cid-saI7ZslBbg .carousel-inner.slides2 > .carousel-item.carousel-item-prev {
    left: 0;
    transform: translate3d(-50%, 0, 0);
  }
  .cid-saI7ZslBbg .carousel-inner.slides2 > .carousel-item.carousel-item-left,
  .cid-saI7ZslBbg
    .carousel-inner.slides2
    > .carousel-item.carousel-item-prev.carousel-item-right,
  .cid-saI7ZslBbg .carousel-inner.slides2 > .carousel-item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  .cid-saI7ZslBbg .carousel-inner.slides2 .cloneditem-1,
  .cid-saI7ZslBbg .carousel-inner.slides2 .cloneditem-2,
  .cid-saI7ZslBbg .carousel-inner.slides2 .cloneditem-3 {
    display: block;
  }
  .cid-saI7ZslBbg
    .carousel-inner.slides3
    > .carousel-item.active.carousel-item-right,
  .cid-saI7ZslBbg .carousel-inner.slides3 > .carousel-item.carousel-item-next {
    left: 0;
    transform: translate3d(33.333333%, 0, 0);
  }
  .cid-saI7ZslBbg
    .carousel-inner.slides3
    > .carousel-item.active.carousel-item-left,
  .cid-saI7ZslBbg .carousel-inner.slides3 > .carousel-item.carousel-item-prev {
    left: 0;
    transform: translate3d(-33.333333%, 0, 0);
  }
  .cid-saI7ZslBbg .carousel-inner.slides3 > .carousel-item.carousel-item-left,
  .cid-saI7ZslBbg
    .carousel-inner.slides3
    > .carousel-item.carousel-item-prev.carousel-item-right,
  .cid-saI7ZslBbg .carousel-inner.slides3 > .carousel-item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  .cid-saI7ZslBbg .carousel-inner.slides3 .cloneditem-1,
  .cid-saI7ZslBbg .carousel-inner.slides3 .cloneditem-2,
  .cid-saI7ZslBbg .carousel-inner.slides3 .cloneditem-3 {
    display: block;
  }
}
@media (max-width: 1200px) {
  .cid-saI7ZslBbg .carousel-inner .carousel-item .cloneditem-2 {
    display: none !important;
  }
}
@media (max-width: 1440px) {
  .cid-saI7ZslBbg .figure:before {
    width: 99px;
    height: 99px;
  }
  .cid-saI7ZslBbg .slider__content .slider__title {
    font-size: 25px;
    line-height: 30px;
  }
  .cid-saI7ZslBbg .slider__content {
    min-height: 235px;
  }
}
@media (max-width: 991px) {
  .cid-saI7ZslBbg .title__block {
    padding-left: 63px;
  }
  .cid-saI7ZslBbg .title__block .mbr-section-title {
    margin-bottom: 0;
  }
  .cid-saI7ZslBbg .figure:before {
    display: none;
  }
  .cid-saI7ZslBbg .mbr-text {
    padding-right: 0;
    margin-bottom: 10px;
  }
  .cid-saI7ZslBbg .carousel-item .slider__item {
    margin-right: 0;
  }
  .cid-saI7ZslBbg .slider__content {
    padding: 18px 18px 22px;
    min-height: 197px;
  }
  .cid-saI7ZslBbg .carousel-controls .carousel-control-next {
    right: -35px;
    bottom: auto;
    top: 44%;
  }
  .cid-saI7ZslBbg .carousel-controls .carousel-control-prev {
    left: -35px;
    bottom: auto;
    top: 44%;
  }
}
@media (max-width: 767px) {
  .cid-saI7ZslBbg .carousel-controls {
    display: none;
  }
}
.cid-saI3SlNkeh {
  min-height: 114px !important;
  position: relative;
  overflow: visible;
  background: #ffffff;
}
.cid-saI3SlNkeh .nav-link,
.cid-saI3SlNkeh .navbar-caption {
  font-weight: 700;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh .navbar-dropdown .navbar-brand span {
  vertical-align: 0;
}
.cid-saI3SlNkeh .container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.cid-saI3SlNkeh .nav-link.text-primary:hover,
.cid-saI3SlNkeh .nav-link.text-primary:focus {
  color: #014188 !important;
}
.cid-saI3SlNkeh .nav-link {
  position: relative;
  line-height: 1;
  margin: 0 !important;
  padding: 10px 20px !important;
}
.cid-saI3SlNkeh .nav-link:hover:before {
  opacity: 1;
}
.cid-saI3SlNkeh .nav-link:before {
  content: "";
  bottom: 0;
  width: calc(100% - 36px);
  left: 18px;
  height: 3px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #dddddd;
  transition: opacity 0.3s linear;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .nav-link {
    margin: 0 !important;
  }
}
.cid-saI3SlNkeh .icons-menu {
  line-height: 0;
}
.cid-saI3SlNkeh .icons__wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  font-size: 29px;
  margin-left: 15px;
}
.cid-saI3SlNkeh
  .navbar-nav
  .nav-item:nth-child(2)
  .dropdown-menu
  .dropdown-item {
  width: 247px;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar-nav .nav-item:nth-child(2) .dropdown-menu .dropdown {
    display: inline-block;
  }
}
.cid-saI3SlNkeh .dropdown-menu .menu__images {
  display: flex;
  transform: translateY(-11px);
}
.cid-saI3SlNkeh .dropdown-menu a {
  overflow: hidden;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours6-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image1:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image2 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours2-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image2:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu a > .image3 {
  width: 250px;
  height: 208px;
  transition: all 0.3s;
  background-image: url("tours1-1-1200x800.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cid-saI3SlNkeh .dropdown-menu a > .image3:hover {
  transform: scale(1.3);
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-item {
  display: inline-flex;
  justify-content: center;
  font-weight: normal;
  padding: 10px 40px 10px 20px;
  line-height: 1;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}
@media (max-width: 1300px) {
  .cid-saI3SlNkeh
    .navbar-nav
    .nav-item:nth-child(2)
    .dropdown-menu
    .dropdown-item {
    width: 167px;
    padding: 10px;
    white-space: normal;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image1 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image2 {
    width: 170px;
    height: 140px;
  }
  .cid-saI3SlNkeh .dropdown-menu a > .image3 {
    width: 170px;
    height: 140px;
  }
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh.dropdown.open
    > .dropdown-menu:not(.dropdown-submenu)
    > .dropdown
    > .dropdown-submenu {
    visibility: visible;
    opacity: 1;
  }
  .cid-saI3SlNkeh .mbr-section-btn {
    margin-left: 31px;
  }
  .cid-saI3SlNkeh .btn {
    white-space: nowrap;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    min-width: 210px;
    display: block;
    z-index: 5;
    background-color: #ffffff !important;
    border: 1px solid #ddebe9;
    border-radius: 4px;
    left: 17px;
    right: auto;
    top: 100%;
    opacity: 0;
    padding: 11px 0 !important;
    margin: 0 !important;
    visibility: hidden;
    transition: all 0.3s linear;
    transform: translateY(0);
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: translateY(20px);
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item.dropdown:hover::before,
  .cid-saI3SlNkeh .nav-item.dropdown.open:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
  }
  .is-builder .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1200px) {
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    align-items: center !important;
  }
}
.cid-saI3SlNkeh .navbar > .container,
.cid-saI3SlNkeh .navbar > .container-fluid {
  align-items: center;
}
.cid-saI3SlNkeh .dropdown-toggle::after,
.cid-saI3SlNkeh .link.dropdown-toggle:after {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 6px;
  content: "";
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  transition: all 0.25s ease 0s;
}
.cid-saI3SlNkeh .dropdown-menu .dropdown-toggle:after {
  border: 2px solid;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}
.cid-saI3SlNkeh .open > .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-bottom: -8px;
  transform: rotate(225deg);
}
.cid-saI3SlNkeh
  .dropdown.open
  .dropdown-toggle[aria-expanded="true"]
  + .dropdown-submenu {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.cid-saI3SlNkeh .nav-dropdown .dropdown-submenu {
  display: none;
  margin: 0 !important;
  font-weight: 400;
  top: 0 !important;
}
.cid-saI3SlNkeh .nav-item:focus,
.cid-saI3SlNkeh .nav-link:focus {
  outline: none;
}
.cid-saI3SlNkeh .dropdown .dropdown-menu .dropdown-item.dropdown-toggle:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-saI3SlNkeh .collapsed .dropdown .dropdown-menu .dropdown-item {
  transition: none;
  margin: 0 !important;
}
.cid-saI3SlNkeh a.nav-link {
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
}
.cid-saI3SlNkeh ul.navbar-nav {
  flex-wrap: wrap;
  flex: 1;
}
.cid-saI3SlNkeh .navbar {
  padding: 12px 52px 11px 43px;
  min-height: 114px;
  transition: all 0.3s;
  background: #ffffff;
}
.cid-saI3SlNkeh .navbar.opened {
  transition: all 0.3s;
  background: #ffffff !important;
}
.cid-saI3SlNkeh .navbar .navbar-collapse {
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}
.cid-saI3SlNkeh .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-saI3SlNkeh .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-saI3SlNkeh .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(100vh - 100px - 1rem);
  }
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu {
  background: transparent !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-saI3SlNkeh .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-saI3SlNkeh .dropdown-menu .menu__images {
    display: none;
  }
  .cid-saI3SlNkeh .nav-item:hover .nav-link + .dropdown-menu {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .cid-saI3SlNkeh .nav-item .nav-link::before {
    display: none;
  }
  .cid-saI3SlNkeh.opened .dropdown-menu {
    top: 0;
  }
  .cid-saI3SlNkeh .mbr-section-btn .btn {
    min-width: auto;
    padding: 5px;
    font-size: 14px;
  }
  .cid-saI3SlNkeh .dropdown-menu {
    margin: 0;
    padding: 0;
    line-height: 45px;
    border: 0;
    left: 8px;
    width: 100%;
    max-width: 100% !important;
    box-shadow: none;
  }
  .cid-saI3SlNkeh .dropdown-menu .dropdown-submenu {
    left: 0 !important;
    position: relative !important;
  }
  .cid-saI3SlNkeh .navbar-logo {
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar-logo img {
    height: 60px !important;
  }
  .cid-saI3SlNkeh .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    margin: 0 !important;
    padding: 0;
  }
  .cid-saI3SlNkeh .navbar-toggler {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .cid-saI3SlNkeh .icons-menu {
    margin: 30px 0;
    text-align: center;
  }
  .cid-saI3SlNkeh .navbar {
    padding: 13px 90px;
  }
  .cid-saI3SlNkeh .navbar .navbar-collapse {
    position: absolute;
    top: 100%;
    background-color: #ffffff !important;
    padding-left: 20px;
    padding-right: 20px;
    left: 0px;
    right: 0px;
  }
  .cid-saI3SlNkeh .navbar .dropdown-menu .dropdown-item {
    justify-content: flex-start;
    padding: 15.5px 23px;
    line-height: 1;
  }
  .cid-saI3SlNkeh .dropdown-submenu .dropdown-item {
    padding-left: 45px !important;
  }
  .cid-saI3SlNkeh .dropdown-toggle::after,
  .cid-saI3SlNkeh .link.dropdown-toggle:after {
    position: absolute;
    right: 12px;
  }
  .cid-saI3SlNkeh .nav-item {
    border-bottom: 1px solid #ddebe9;
  }
  .cid-saI3SlNkeh a.nav-link {
    justify-content: flex-start;
    line-height: 21px;
    padding: 35px 43px 35px 19px !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 100px;
    margin: 0;
  }
  .cid-saI3SlNkeh .navbar .dropdown.open > .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}
.cid-saI3SlNkeh.navbar-short {
  background: #ffffff !important;
  min-height: 70px;
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .nav-dropdown {
  height: 70px;
}
.cid-saI3SlNkeh.navbar-short .navbar-brand {
  padding: 0;
}
.cid-saI3SlNkeh.navbar-short .navbar-logo img {
  height: 70px !important;
}
@media (max-width: 767px) {
  .cid-saI3SlNkeh .navbar {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh ul.navbar-nav {
    padding: 0 !important;
  }
  .cid-saI3SlNkeh a.nav-link {
    padding: 27px 26px 27px 19px !important;
  }
}
.cid-saI3SlNkeh .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 12px;
  padding: 0;
  transition: all 0.3s;
  word-break: break-word;
}
.cid-saI3SlNkeh .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-saI3SlNkeh .dropdown-item.active,
.cid-saI3SlNkeh .dropdown-item:active {
  background-color: transparent;
}
.cid-saI3SlNkeh .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.cid-saI3SlNkeh
  .navbar.navbar-expand-lg
  .dropdown
  .dropdown-menu
  .dropdown-submenu {
  left: 100%;
  position: absolute;
}
.cid-saI3SlNkeh button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #f7941e;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-saI3SlNkeh button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-saI3SlNkeh nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.is-builder .cid-saI3SlNkeh .dropdown.open > .dropdown-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(20px);
}
.cid-saI3SlNkeh .navbar-dropdown {
  position: absolute;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-saI3SlNkeh .navbar {
    height: 97px;
  }
  .cid-saI3SlNkeh .navbar.opened {
    height: auto;
  }
  .cid-saI3SlNkeh .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-saI3SnlaZp {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: #ececec;
}
.cid-saI3SnlaZp .nav__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 7px;
}
.cid-saI3SnlaZp .menu__footer {
  width: 50%;
  padding-right: 40px;
  margin-bottom: 30px;
}
.cid-saI3SnlaZp .mbr-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cid-saI3SnlaZp .menu__item {
  margin-bottom: 20px;
}
.cid-saI3SnlaZp .menu__item a {
  font-weight: 600;
}
.cid-saI3SnlaZp .widget__title {
  line-height: 1.17;
  margin-bottom: 20px;
  color: #f7941e;
}
.cid-saI3SnlaZp .widget__feed {
  max-width: 305px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cid-saI3SnlaZp .widget__item {
  display: block;
  width: 92px;
  height: 92px;
  margin-bottom: 13px;
}
.cid-saI3SnlaZp .widget__item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .cid-saI3SnlaZp .menu__item {
    text-align: center;
  }
  .cid-saI3SnlaZp .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
