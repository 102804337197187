.tether-element,
.tether-element *,
.tether-element :after,
.tether-element :before,
.tether-element:after,
.tether-element:before {
  box-sizing: border-box;
}
.tether-element {
  position: absolute;
  display: none;
}
.tether-element.tether-open {
  display: block;
}
