.gdpr-block {
  padding: 10px;
  font-size: 14px;
  display: block;
  width: 100%;
  text-align: center;
}
.gdpr-block.covert {
  display: none;
}
.textGDPR {
  position: relative;
}
.gdpr-block label span.textGDPR input[name="gdpr"] {
  width: 15px;
  height: 15px;
  margin: 0;
  position: absolute;
  top: 2px;
  left: -20px;
}
.gdpr-block label {
  color: #a7a7a7;
  vertical-align: middle;
  user-select: none;
  margin-bottom: 0;
}
